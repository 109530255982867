import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';

const DaySelection = ({ control, name, label, errors, disabledOptions }) => {
  const weekDays = [
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
  ];

  const filteredDays = weekDays.filter(
    week => !disabledOptions.includes(week.value)
  );

  return (
    <Col xxl='6' className='mb-3'>
      <Form.Group>
        <label className=' fw-medium' style={{ marginBlockEnd: '12px' }}>
          {label}
        </label>
        <Controller
          control={control}
          name={name}
          defaultValue=''
          render={({ field }) => (
            <Form.Control as='select' {...field}>
              <option value=''>Select Week</option>
              {filteredDays?.map(week => (
                <option key={week.value} value={week.value}>
                  {week.label}
                </option>
              ))}
            </Form.Control>
          )}
        />
        {errors?.message && (
          <Form.Control.Feedback type='invalid' className='d-block'>
            {errors?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  );
};

////// solve prop issue which getting via eslint ////
DaySelection.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
  }),
  disabledOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DaySelection;
