import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";



function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  useEffect(() => {
    const pathPattern = /[ ]$/g;
    if (pathPattern.test(pathname)) {
      navigate(pathname.replace(pathPattern, ""), { replace: true });
    }
  }, [navigate, pathname]);

  return <Outlet />;
}

export default AdminLayout;




