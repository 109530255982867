import React from "react";
import { PiSquaresFour } from "react-icons/pi";
import { Dashboard } from "../../../pages";


export default function route() {
  return [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      key: "/admin/dashboard",
      private: true,
      belongsToSidebar: true,
      icon: <PiSquaresFour size={32} />,
      element: <Dashboard />,

    },
  ]
}