
import { ManageCatelogsAPI } from "../../apiendpoints";
import { APIrequest } from "../../utils";

export const CatelogsService = {
  getCatelogsListService: async ({ queryParams }) => {
    try {
      const response = await APIrequest({
        method: ManageCatelogsAPI.getCateglogs.method,
        url: ManageCatelogsAPI.getCateglogs.url,
        queryParams
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  createCatelogsService: async (fromData) => {
    try {
      const response = await APIrequest({
        method: ManageCatelogsAPI.createCateglogs.method,
        url: ManageCatelogsAPI.createCateglogs.url,
        fromData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  editCatelogsService: async (id, fromData) => {
    try {
      const response = await APIrequest({
        method: ManageCatelogsAPI.editCateglogs(id).method,
        url: ManageCatelogsAPI.editCateglogs(id).url,
        fromData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  deleteCatelogsService: async (id) => {
    try {
      const response = await APIrequest({
        method: ManageCatelogsAPI.deleteCatelogsById(id).method,
        url: ManageCatelogsAPI.deleteCatelogsById(id).url,
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },






};
