import React from "react";
import FormInput from "../UiElement/textInput";
import { Form, Button, Row, Modal } from "react-bootstrap";
import { ImageUploader } from "../UiElement";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
export const AddEditCatelogs = React.memo(
  ({
    handleSubmit,
    register,
    errors,
    setValue,
    onSubmit,
    clearErrors,
    watch,
    isLoading = false,
    id = false,
    selectedImage,
    setSelectedImage,
  }) => {
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <>
              <ImageUploader
                label="Catalogs Image"
                name="catelogs_image"
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                col={12}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                multiple={false}
              />

              <FormInput
                label="Catalogs Name"
                type="text"
                placeholder="Enter your catalog name"
                name="catelog_name"
                register={register}
                errors={errors}
                col={12}
              />
              <FormInput
                label="Description"
                type="textarea"
                register={register}
                setValue={setValue}
                name="description"
                errors={errors}
                clearErrors={clearErrors}
                value={watch().description}
                col={12}
              />
            </>
          </Row>

          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {id && Object.keys(id).length > 0 ? "Save" : "Add"}
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }
);

AddEditCatelogs.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearErrors: PropTypes.func,
  watch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedImage: PropTypes.any,
  setSelectedImage: PropTypes.func.isRequired,
};