/* eslint-disable react/prop-types */
import React from "react";
import { Col, Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Optional for skeleton styles

const SelectInput = ({
  label,
  name,
  options,
  isLoading,
  register,
  validation,
  errors,
  selectedValue,
  col = 6,
  onChange,
  setValue,
  disabled = false,
  isCountryStateCity = false
}) => {
  return (
    <Col lg={col}>
      <Form.Group className='mb-3' controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label>
        {isLoading ? (
          <Skeleton height={38} count={1} />
        ) : (
          <Form.Control
            as='select'
            name={name}
            value={selectedValue}
            {...register(name, validation)}
            isInvalid={!!errors[name]}
            onChange={val => {
              setValue(name, val.target.value, { shouldValidate: true });
              onChange && onChange(val);
            }}
            disabled={disabled}
          >
            <option value=''>Select an option</option>
            {options?.map(({ isoCode, name, id }) => (
              <option
                value={isCountryStateCity ? id : isoCode} key={`${id}-${isoCode}`}
              >
                {name}
              </option>
            ))}
          </Form.Control>
        )}
        {errors[name] && (
          <Form.Control.Feedback type='invalid'>
            {errors[name]?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  );
};

export default SelectInput;
