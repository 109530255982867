import React from "react";
import FormInput from "../UiElement/textInput";
import { Form, Button, Row } from "react-bootstrap";
import { TextEditor } from "../UiElement";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';


export const AddEditCmsForm = React.memo(
  ({ handleSubmit, register, errors, setValue, onSubmit, clearErrors, watch, isLoading }) => {
    const { id } = useParams()
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <FormInput
              label="Title"
              type="text"
              placeholder="Enter your title"
              name="title"
              register={register}
              errors={errors}
              col={12}
            />
            <TextEditor
              label="Description"
              register={register}
              setValue={setValue}
              name="description"
              errors={errors}
              clearErrors={clearErrors}
              value={watch().description}
            />
          </Row>

          <Button variant="primary" type="submit"
            disabled={isLoading}
          >
            {id ? "Save" : "Add"}

          </Button>
        </Form>
      </>
    );
  }
);

AddEditCmsForm.displayName = 'AddEditCmsForm';

AddEditCmsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, 
  register: PropTypes.func.isRequired, 
  errors: PropTypes.object.isRequired, 
  setValue: PropTypes.func.isRequired, 
  onSubmit: PropTypes.func.isRequired, 
  clearErrors: PropTypes.func.isRequired, 
  watch: PropTypes.func.isRequired, 
  isLoading: PropTypes.bool.isRequired, 
};