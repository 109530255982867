import { createColumnHelper } from "@tanstack/react-table";
import React,{ useEffect, useState, useCallback } from "react";
import { Button, Card } from "react-bootstrap";
import { PiTrash } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import {
  AddEditTreatmentForm,
  BreadCrumb,
  CommonModal,
  CommonPagination,
  CommonTable,
  ShowAlert,
  showAlertMessage,
} from "../../../../components";
import { useFormHook } from "../../../../hooks";
import { treatmentCategorySchema } from "../../../../components/forms/commonVlidation";
import { ManageTreatmentService } from "../../../../services";

const TreatmentsCategroy = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormLoading, setFormIsLoading] = useState();
  const [treatmentDataCategories, setTreatmentCategoriesData] = useState();
  const [itemsPerPage] = useState(10);
  const [getTreatmentList, setTreatmentList] = useState();
  const [getTreatmentDetails, setTreatmentDetails] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [userPagination, setUserPagination] = useState()

  const fetchTreatmentCategroy = useCallback(async () => {
    setIsLoading(true);
    const queryParams = {
      page: page,
      per_page: itemsPerPage,
    };
    try {
      const res =
        await ManageTreatmentService.getTreatmentCategoriesListService({
          queryParams,
        });
      const { success, data, pagination } = res;
      if (success) {
        if (data?.length) {
          setTreatmentCategoriesData(data);
          setUserPagination(pagination)

        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [itemsPerPage, page]);

  useEffect(() => {
    fetchTreatmentCategroy();
  }, [fetchTreatmentCategroy, page]);

  const handleDelete = async (id) => {
    const confirmed = await ShowAlert(
      "Confirm Action",
      "Do you really want to delete treatment?"
    );
    try {
      if (confirmed === "approved") {
        const responseApproval =
          await ManageTreatmentService.deleteTreatmentService(id, true);
        const { success, message } = responseApproval;
        if (success) {
          showAlertMessage(message, "success");
          fetchTreatmentCategroy();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const BreadcrumbData = [
    {
      title: "Treatment Category",
      url: "/admin/treatment/category",
    },
  ];

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.treatment?.name, {
      id: "treatment",
      header: () => <>Treatment Name</>,
      cell: (row) => {
        return row?.getValue();
      },
    }),
    columnHelper.accessor((row) => `${row?.category_name}`, {
      id: "category_name",
      header: () => <>Category name</>,
      cell: (row) => {
        return row?.getValue();
      },
    }),
    // columnHelper.accessor((row) => row?.description, {
    //   id: "description",
    //   header: () => <>Description</>,
    //   cell: (info) => {
    //     return info.getValue();
    //   },
    // }),
    columnHelper.accessor((row) => row, {
      id: "actions",
      header: () => <>Action</>,
      cell: ({ row }) => {
        const rowData = row.original || {};
        return (
          <>
            <div className="custom-table-dropdown hstack gap-2">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                  setTreatmentDetails(rowData);
                }}
                className="btn  btn-light rounded-3 p-1"
              >
                <MdEdit size={20} />
              </Link>
              <div className="btn btn-danger rounded-3 p-1">
                <PiTrash size={20} onClick={() => handleDelete(rowData?.id)} />
              </div>
            </div>
          </>
        );
      },
    }),
  ];

  const inisitaData = {
    treatmentName: "",
    treatmentCategory: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    errors,
    watch,
    control
  } = useFormHook(inisitaData, treatmentCategorySchema);

  useEffect(() => {
    if (getTreatmentDetails && Object.keys(getTreatmentDetails).length > 0) {
      reset({
        treatmentName: getTreatmentDetails?.treatment_id,
        treatmentCategory: getTreatmentDetails?.category_name,
      });
    } else {
      reset({
        treatmentName: "",
        treatmentCategory: "",

      });
    }
  }, [getTreatmentDetails, isModalOpen, reset]);

  const onSubmit = async (data) => {
    const bodyData = {
      treatment_id: data?.treatmentName,
      category_name: data?.treatmentCategory,
    }
    try {
      setFormIsLoading(true);
      const response =
        Object?.keys(getTreatmentDetails).length > 0
          ? await ManageTreatmentService.editTreatmentCategoryService(
            getTreatmentDetails?.id,
            bodyData
          )
          : await ManageTreatmentService.createTreatmentsCategoriesService(bodyData)

      const { success, message } = response;
      if (success) {
        setFormIsLoading(false);
        setIsModalOpen(false);
        showAlertMessage(message, "success");
        fetchTreatmentCategroy();
      }
      setFormIsLoading(false);
    } catch (error) {
      console.log(error);
      setFormIsLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const fetchTreatmentOnly = async () => {
      setIsLoading(true);
      const queryParams = {
        page: page,
        limit: itemsPerPage,
      };
      try {
        const res = await ManageTreatmentService.getTreatmentListService({
          queryParams,
        });
        const { success, data } = res;
        if (success) {
          if (data?.length) {
            setTreatmentList(data);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchTreatmentOnly();
  }, [itemsPerPage, page]);

  return (
    <>
      <section>
        <div className="text-end">
          <Button
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setTreatmentDetails({});
              setSelectedImage("");
            }}
          >
            Add Treatments Category
          </Button>
        </div>
      </section>

      <section className="user-list">
        <section>
          <BreadCrumb breadcrumbData={BreadcrumbData} />
        </section>
        <Card>
          <Card.Body className="p-0">
            <CommonTable
              columns={columns}
              data={treatmentDataCategories}
              loading={isLoading}
              keyValue="id"
              rowclick={["actions", "status"]}
              excludes={["actions", "status"]}
            />
          </Card.Body>
          <Card.Footer className="border-top-0">
            <CommonPagination
              activePage={page}
              pageCount={Math.ceil(userPagination?.total / itemsPerPage)}
              onPageChange={(e) => {
                setPage(e);
              }}
              className="gap-2 justify-content-end mb-0"
            />
          </Card.Footer>
        </Card>
        <CommonModal
          show={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          title={getTreatmentDetails && Object?.keys(getTreatmentDetails).length > 0 ? "Edit Treatments Categegory" : "Add Treatments Categegory"}
          // eslint-disable-next-line react/no-children-prop
          children={
            <AddEditTreatmentForm
              control={control}
              handleSubmit={handleSubmit}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              setValue={setValue}
              clearErrors={clearErrors}
              watch={watch}
              isLoading={isFormLoading}
              isTreatment={true}
              getTreatmentList={getTreatmentList}
              id={getTreatmentDetails}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          }
          footer="footere"
        />
      </section>
    </>
  );
};

export default TreatmentsCategroy;
