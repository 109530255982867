import Logo from "../image/logo.png";
import loginBg from "../image/login-bg.png";
import serviceImg from "../image/service-img.png";
import detailImg from "../image/detail-img.png";
import companyLogo from "../image/company-logo.svg";
import job from "../image/job.jpg";
import blueBg from "../image/blue-bg.png";
import profile from "../image/profile.png";
import bannerImg from "../image/banner-img.png";
import lines from "../image/lines.png";
import person from "../image/person.png";
import service from "../image/service.png";
import benefits from "../image/benefits.png";
import companyEditProfileLogo from '../image/companyEditProfileLogo.jpeg'
import beautyApp from "../image/beauty.jpeg"
import beautyAppLogo from "../image/beauty-app-logo.png"
import profilePlaceholder from "../image/profile-placeholder.svg"
export {
    Logo,
    loginBg,
    serviceImg,
    detailImg,
    companyLogo,
    job,
    blueBg,
    profile,
    companyEditProfileLogo,
    bannerImg,
    lines,
    person,
    service,
    benefits,
    beautyApp,
    beautyAppLogo,
    profilePlaceholder
}