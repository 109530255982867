import React from "react";
import { TechnicalAndSupport } from "../../../pages";
import { PiInfo } from "react-icons/pi";

export default function route() {
  return [
    {
      path: "/admin/technical-support",
      name: "Technical And Support",
      key: "/admin/technical-support",
      private: true,
      belongsToSidebar: true,
      icon: <PiInfo size={32} />,
      element: <TechnicalAndSupport />,
    },
  ]
}