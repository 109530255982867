
import React from "react";
import { AdminLayout } from "../layouts";
import { NotFound } from "../pages";
import { adminRoutes } from "./admin/index";

export const routes = () => {
  return [
    {
      element: <AdminLayout />,
      children: [...adminRoutes()],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
};


export const routesList = () => {
  const routeArr = [
    ...adminRoutes()[0].children,
    ...adminRoutes()[1].children,
  ];
  return [...routeArr];
};

export const moduleRoutesList = () => {
  const routeArr = {
    admin: [...adminRoutes()[0].children, ...adminRoutes()[1].children],
  };
  return routeArr;
};


export const getCompletePathList = () => {
  return routesList().reduce((prev, curr) => {
    prev.push(curr);
    if (curr.children) {
      prev.push(...curr.children);
    }
    return prev;
  }, []);
};