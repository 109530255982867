import { createColumnHelper } from "@tanstack/react-table";
import React,{ useEffect, useState, useCallback } from "react";
import { Button, Card } from "react-bootstrap";
import { PiTrash } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import {
  AddEditTreatmentForm,
  BreadCrumb,
  CommonModal,
  CommonPagination,
  CommonTable,
  ShowAlert,
  showAlertMessage,
} from "../../../../components";
import { useFormHook } from "../../../../hooks";
import { treatmentSchema } from "../../../../components/forms/commonVlidation";
import { ManageTreatmentService } from "../../../../services";
import { dateFormatter } from "../../../../helper/commonHelper";

const Treatments = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormLoading, setFormIsLoading] = useState();
  const [treatmentData, setTreatmentData] = useState();
  const [itemsPerPage] = useState(10);
  const [getTreatmentDetails, setTreatmentDetails] = useState();
  const [userPagination, setUserPagination] = useState();
  const handleDelete = async (id) => {
    const confirmed = await ShowAlert(
      "Confirm Action",
      "Do you really want to delete treatment?"
    );
    try {
      if (confirmed === "approved") {
        const responseApproval =
          await ManageTreatmentService.deleteTreatmentService(id);
        const { success, message } = responseApproval;
        if (success) {
          fetchTreatmentList();
          showAlertMessage(message, "success");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const BreadcrumbData = [
    {
      title: "Treatment",
      url: "/admin/treatment/treatments",
    },
  ];

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.name, {
      id: "name",
      header: () => <>Name</>,
      cell: (row) => {
        return row?.getValue();
      },
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: "createdAt",
      header: () => <>Posted Date</>,
      cell: (info) => {
        return dateFormatter(info?.getValue(), "DD/MM/YYYY");
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "actions",
      header: () => <>Action</>,
      cell: ({ row }) => {
        const rowData = row.original || {};
        return (
          <>
            <div className="custom-table-dropdown hstack gap-2">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                  setTreatmentDetails(rowData);
                }}
                className="btn  btn-light rounded-3 p-1"
              >
                <MdEdit size={20} />
              </Link>
              <div className="btn btn-danger rounded-3 p-1">
                <PiTrash size={20} onClick={() => handleDelete(rowData?.id)} />
              </div>
            </div>
          </>
        );
      },
    }),
  ];
  const inisitaData = {
    treatmentName: "",
    treatmentBody: "",
    treatmentParts: "",
    gender: ""
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    errors,
    watch,
    control
  } = useFormHook(inisitaData, treatmentSchema);

  useEffect(() => {
    if (getTreatmentDetails) {
      reset({
        treatmentName: getTreatmentDetails?.name,
        treatmentBody: `${getTreatmentDetails?.body_face}`,
        treatmentParts: `${getTreatmentDetails?.treatment_for}`,
        gender: `${getTreatmentDetails?.gender}`,

      });
    }
  }, [getTreatmentDetails, reset]);
  const fetchTreatmentList = useCallback(async () => {
    setIsLoading(true);
    const queryParams = {
      page: page,
      per_page: itemsPerPage,
    };
    try {
      const res = await ManageTreatmentService.getTreatmentListService({
        queryParams,
      });
      const { success, data, pagination } = res;
      if (success) {
        if (data?.length) {
          setTreatmentData(data);
          setUserPagination(pagination);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [itemsPerPage, page]);

  useEffect(() => {
    fetchTreatmentList();
  }, [fetchTreatmentList, page]);

  useEffect(() => {
    if (!isModalOpen) {
      setTreatmentDetails({})
    }
  }, [isModalOpen])

  const onSubmit = async (data) => {
    const dataPayload = {
      name: data?.treatmentName,
      body_face: data?.treatmentBody,
      gender: data?.gender,
      treatment_for: data?.treatmentParts,
      problems: data?.problems
    };
    try {
      setFormIsLoading(true);
      const response =
        Object?.keys(getTreatmentDetails).length > 0
          ? await ManageTreatmentService.editTreatmentService(
            getTreatmentDetails?.id,
            dataPayload
          )
          : await ManageTreatmentService.createTreatmentsService(dataPayload);

      const { success, message } = response;
      if (success) {
        setFormIsLoading(false);
        setIsModalOpen(false);
        showAlertMessage(message, "success");
        fetchTreatmentList();
        reset({
          treatmentName: "",
          treatmentBody: "",
          treatmentParts: "",
          gender: ""
        });
      }
      setFormIsLoading(false);
    } catch (error) {
      console.log(error);
      setFormIsLoading(false);
      setIsModalOpen(false);
    }
  };
  return (
    <>
      <section>
        <div className="text-end">
          <Button
            onClick={() => {
              reset({
                treatmentName: "",
              });
              setIsModalOpen(!isModalOpen);
            }}
          >
            Add Treatments
          </Button>
        </div>
      </section>

      <section className="user-list">
        <section>
          <BreadCrumb breadcrumbData={BreadcrumbData} />
        </section>
        <Card>
          <Card.Body className="p-0">
            <CommonTable
              columns={columns}
              data={treatmentData}
              loading={isLoading}
              keyValue="id"
              rowclick={["actions", "status"]}
              excludes={["actions", "status"]}
            />
          </Card.Body>
          <Card.Footer className="border-top-0">
            <CommonPagination
              activePage={page}
              pageCount={Math.ceil(userPagination?.total / itemsPerPage)}
              onPageChange={(e) => {
                setPage(e);
              }}
              className="gap-2 justify-content-end mb-0"
            />
          </Card.Footer>
        </Card>

        <CommonModal
          show={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          title={getTreatmentDetails && Object?.keys(getTreatmentDetails).length > 0 ? "Edit Treatments" : "Add Treatments"}
          // eslint-disable-next-line react/no-children-prop
          children={
            <AddEditTreatmentForm
              control={control}
              handleSubmit={handleSubmit}
              register={register}
              errors={errors}
              setValue={setValue}
              onSubmit={onSubmit}
              watch={watch}
              isLoading={isFormLoading}
              id={getTreatmentDetails}
              // fieldsArray={getTreatmentDetails?.problems}
              fieldsArray={getTreatmentDetails?.problems?.length > 0 ? getTreatmentDetails?.problems : [{
                statement: ""
              }]}
              reset={reset}
              isVisible={isModalOpen}
            />
          }
          footer="footere"
        />
      </section>
    </>
  );
};

export default Treatments;
