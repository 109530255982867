import React from "react";
import FormInput from "../UiElement/textInput";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import SelectInput from "../UiElement/selectInput";
import { RadioInput } from "../UiElement";
import { useFieldArray } from "react-hook-form";
import { PiPlus, PiMinus } from "react-icons/pi";
import PropTypes from 'prop-types';

export const AddEditTreatmentForm = React.memo(
  ({
    control, // Receive control as a prop here
    handleSubmit,
    register,
    errors,
    setValue,
    onSubmit,
    watch,
    isLoading = false,
    isTreatment = false,
    getTreatmentList = [],
    id = false,
    fieldsArray,
    reset,
    isVisible
  }) => {
    const genderOptions = [
      { label: "Male", value: 1 },
      { label: "Female", value: 0 },
    ];

    const treatmentOptions = [
      { label: "Face", value: 0 },
      { label: "Body", value: 1 },
    ];

    const faceParts = [
      { label: "Forehead", value: "forehead" },
      { label: "Hair", value: "hair" },
      { label: "Eyes", value: "eyes" },
      { label: "Nose", value: "nose" },
      { label: "Ears", value: "ears" },
      { label: "Cheeks", value: "cheeks" },
      { label: "Lips & Mouth", value: "lips_mouth" },
      { label: "Teeth", value: "teeth" },
      { label: "Smile", value: "smile" },
      { label: "Chin", value: "chin" },
      { label: "Neck & Throat", value: "neck_throat" },
      { label: "Eyebrows", value: "eyebrows" }
    ];


    const bodyParts = [
      { label: "Arm", value: "arm" },
      { label: "Chest", value: "chest" },
      { label: "Stomach", value: "stomach" },
      { label: "Leg", value: "leg" },
      { label: "Genital", value: "genital" },
      { label: "Butt", value: "butt" },
      { label: "Hand", value: "hand" },
      { label: "Foot", value: "foot" }
    ];

    const { fields, append, remove } = useFieldArray({
      control,
      name: "problems",
    });

    React.useEffect(() => {
      // Check if it's not a treatment and fieldsArray has data to append
      if (!isTreatment && fieldsArray?.length > 0) {
        // Check if the fieldsArray has not been appended already (to prevent the infinite loop)
        if (fields.length === 0) {
          reset({ problems: [] });
          fieldsArray.forEach((item) => {
            append({ statement: item?.statement });
          });
        }
      }
    }, [fieldsArray, isTreatment, reset, fields.length, append]);


    React.useEffect(() => {
      if (!isVisible && !isTreatment) {
        reset({ problems: [] });
      }
    }, [isTreatment, isVisible, reset]);

    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {isTreatment ? (
              <>
                <SelectInput
                  label="Treatment Name"
                  name="treatmentName"
                  options={getTreatmentList.map((item) => {
                    return {
                      isoCode: item?.id,
                      name: item?.name,
                    };
                  })}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  col={12}
                />
                <FormInput
                  label="Treatment Category"
                  type="text"
                  placeholder="Enter your treatment category"
                  name="treatmentCategory"
                  register={register}
                  errors={errors}
                  col={12}
                />
              </>
            ) : (
              <>
                <RadioInput
                  label="Gender"
                  name="gender"
                  options={genderOptions}
                  register={register}
                  validation={{ required: "Please select your gender." }}
                  errors={errors}
                  col={12}
                />
                <RadioInput
                  label="Discover Body"
                  name="treatmentBody"
                  options={treatmentOptions}
                  register={register}
                  validation={{
                    required: "Please select your treatment body.",
                  }}
                  errors={errors}
                  col={12}
                />

                {watch().treatmentBody && (
                  <>
                    <RadioInput
                      label={`${watch().treatmentBody === "0" ? "Face" : "Body"
                        } Treatment`}
                      name="treatmentParts"
                      options={
                        watch().treatmentBody === "0" ? faceParts : bodyParts
                      }
                      register={register}
                      validation={{
                        required: "Please select at least one option.",
                      }}
                      errors={errors}
                      col={12}
                    />

                    <FormInput
                      label="Suggested Treatment"
                      type="text"
                      placeholder="Enter your suggested name"
                      name="treatmentName"
                      register={register}
                      errors={errors}
                      col={12}
                    />
                    <Col lg={12}>
                      <label>Problem Statement</label>
                      {fields.map((item, index) => (
                        <Row key={item.id} className="mb-2">
                          <Col lg={10}>
                            <FormInput
                              type="text"
                              placeholder="Enter your problem statement"
                              name={`problems[${index}].statement`}
                              register={register}
                              errors={errors}
                              col={12}
                              defaultValue={item.statement}
                            />
                          </Col>
                          <Col lg={2} className="mt-3">
                            {/* Show Remove button if there are multiple fields and it's not the last one */}
                            {fields.length > 1 &&
                              index !== fields.length - 1 && (
                                <Button
                                  variant="danger"
                                  onClick={() => remove(index)}
                                >
                                  <PiMinus />
                                </Button>
                              )}
                            {index === fields.length - 1 && (
                              <Button
                                variant="secondary"
                                onClick={() => append({ statement: "" })}
                              >
                                <PiPlus />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>

          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {Object.keys(id).length > 0 ? "Save" : "Add"}
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }
);


AddEditTreatmentForm.displayName = 'AddEditTreatmentForm';

AddEditTreatmentForm.propTypes = {
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired, // Add validation for onSubmit
  watch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isTreatment: PropTypes.bool,
  getTreatmentList: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fieldsArray: PropTypes.array,
  reset: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};
