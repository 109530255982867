import React from "react";
import { Button, Form } from "react-bootstrap";
import { FormInput } from "../UiElement";
import PropTypes from 'prop-types';

export const ChangePasswordForm = ({
  handleSubmit,
  register,
  errors,
  onSubmit,
  isLoading,
}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Current Password"
        type="password"
        placeholder="Enter your current password"
        name="old_password"
        register={register}
        errors={errors}
        col={9}
      />
      <FormInput
        label="New Password"
        type="password"
        placeholder="Enter your new password"
        name="new_password"
        register={register}
        errors={errors}
        col={9}

      />
      <FormInput
        label="Confirm New Password"
        type="password"
        placeholder="Enter your confirm new password"
        name="confirm_password"
        register={register}
        errors={errors}
        col={9}

      />

      <Button
        type="submit"
        variant="primary"
        className="me-2 rounded-pill px-3"
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Save"}
      </Button>
    </Form>
  );
};


ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired, 
  isLoading: PropTypes.bool.isRequired,
};