export const AuthAPI = {
  login: {
    url: '/admin/login',
    method: 'POST',
  },
  forgotPassword: {
    url: '/admin/forgot-password',
    method: 'POST',
  },
  // resetPassword: {
  //   url: '/reset-password',
  //   method: 'POST',
  // },
  profile: {
    url: "/admin/update-profile",
    method: "PUT"
  },
  changePassword: {
    url: '/admin/change-password',
    method: 'POST',
  },
  profileGet: {
    url: '/admin/me',
    method: 'GET',
  },
  resetPassword: {
    url: '/admin/reset-password',
    method: 'POST',
  },
};
