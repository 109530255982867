import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const useFormHook = (initialData, schema = false) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm({ defaultValues: initialData, resolver: schema && yupResolver(schema) });

  return {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    control,
    errors,
  };
};
