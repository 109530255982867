import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AppLayout from '../appLayout'

export default function AdminPublicLayout() {
  const [redirectpath, setRedirectPath] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [navigate, redirectpath]);


  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath} >
        <Outlet />
      </AppLayout>
    </>
  )
}
