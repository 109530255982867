
import { ManageCmsAPI, } from "../../apiendpoints";
import { APIrequest } from "../../utils";

export const ManageCmsService = {
  getCmsListService: async ({ queryParams }) => {
    try {
      const response = await APIrequest({
        method: ManageCmsAPI.getCms.method,
        url: ManageCmsAPI.getCms.url,
        queryParams
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  createCmsService: async (id, bodyData) => {
    try {
      const response = await APIrequest({
        method: ManageCmsAPI.editCms(id).method,
        url: ManageCmsAPI.editCms(id).url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getCmsByIdService: async ({ queryParams }) => {
    try {
      const response = await APIrequest({
        method: ManageCmsAPI.getCmsId.method,
        url: ManageCmsAPI.getCmsId.url,
        queryParams
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },

};
