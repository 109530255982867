import React from "react";
import FormInput from "../UiElement/textInput";
import { Form, Button, Row } from "react-bootstrap";
import { ImageUploader } from "../UiElement";
import PropTypes from 'prop-types';

export const EditProfileForm = React.memo(
  ({
    handleSubmit,
    register,
    errors,
    setValue,
    onSubmit,
    watch,
    isLoading,
    setSelectedImage,
    selectedImage
  }) => {
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <ImageUploader
              label="Profile Image"
              name="profile_image"
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              col={6}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
            <FormInput
              label="First Name"
              type="text"
              placeholder="Enter your first name"
              name="first_name"
              register={register}
              errors={errors}
              col={6}
              disabled={isLoading}
            />
            <FormInput
              label="Last Name"
              type="text"
              placeholder="Last Name"
              name="last_name"
              register={register}
              errors={errors}
              col={6}
              disabled={isLoading}
            />

            <FormInput
              label="Email"
              type="email"
              placeholder="name@example.com"
              name="email"
              register={register}
              errors={errors}
              col={6}
              readOnly
              disabled={true}
            />
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Save
          </Button>
        </Form>
      </>
    );
  }
);

EditProfileForm.displayName = 'EditProfileForm';

EditProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  selectedImage: PropTypes.string, 
};