import Cms from "../admin/manageCms/index";
import Manageuser from "../admin/manageUsers/index"
import Dashboard from "../admin/dashbaord/index"
import Communication from "../admin/communication/index"
import Profile from "../admin/profile/index";
import TechnicalAndSupport from "../admin/technicalAndSupport/index"
import Catelogs from "../admin/catelog/index"
import Treatments from "../admin/treatmentManagement/index";

export default function route() {
  return [
    ...Dashboard(),
    ...Manageuser(),
    ...Cms(),
    ...Communication(),
    ...Profile(),
    ...TechnicalAndSupport(),
    ...Catelogs(),
    ...Treatments(),

  ];
}
