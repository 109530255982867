
import { useLocation, generatePath, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { authDriver } from "../../utils";
import { getCompletePathList } from "../../routes";
import PropTypes from 'prop-types';


function AppLayout(props) {
  const { setRedirectPath, children } = props
  const location = useLocation();
  const params = useParams();
  const userData = {
    userRole: "admin"
  };

  function getGeneratedPath(data) {
    try {
      return generatePath(data, params);
    } catch (error) {
      return data;
    }

  }
  const activeRoute = getCompletePathList().find((e) => {
    const routePath = getGeneratedPath(e.key);
    const browserPath = location.pathname;
    return routePath === browserPath;
  });

  const isPrivate = activeRoute?.private;
  const isValid = authDriver(activeRoute, userData, location.pathname);

  useEffect(() => {
    function checkValid() {
      if (!isValid) {
        let publicPath = "/";
        let privatePath = "";
        let path = "";
  
        if (location.pathname.search("admin") >= 0) {
          path = "admin";
        }
  
        const checkData = userData?.userRole ?? path;
        if (checkData === "admin") {
  
          privatePath = "/admin/dashboard";
          publicPath = "/login";
        } else {
  
          publicPath = "/";
          privatePath = "/admin/dashbaord";
        }
        if (isPrivate === true) {
          setRedirectPath(publicPath);
        } else if (isPrivate === false) {
          setRedirectPath(privatePath);
        }
      } else {
        if (location.pathname === "/") {
          setRedirectPath("/login");
        }
      }
    }

    checkValid();
  }, [isPrivate, isValid, location.pathname, setRedirectPath, userData?.userRole]);

  return <>{isValid && children}</>;
}

// PropTypes validation
AppLayout.propTypes = {
  setRedirectPath: PropTypes.func.isRequired, // Validate setRedirectPath as a required function
  children: PropTypes.node.isRequired // Validate children as required node
};


export default AppLayout;
