
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AlertMessage = () => {

  return (
    <>
      <ToastContainer
        autoClose={2000}

      />
    </>
  );
};

const notifyInstance = (message, type) => {
  switch (type) {
    case 'success':
      toast.success(message, { position: "top-right" });
      break;
    case 'warning':
      toast.warn(message, { position: "top-right" });
      break;
    case 'error':
      toast.error(message, { position: "top-right" });
      break;
    default:
      break;
  }
};

export const showAlertMessage = notifyInstance;


