import React, { useEffect, useState } from 'react'
import { Dropdown, Image } from 'react-bootstrap'
import { profile, profilePlaceholder } from '../../../styles/image'
import { PiPassword, PiSignOut, PiUser } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeLocalStorageItem } from "../../../helper/commonHelper";
import { logoutAction, updateUserDataAction } from "../../../redux/authslice";
import { AuthService } from '../../../services/Auth/index.service';
import PropTypes from 'prop-types';


export default function AdminHeader({ setRedirectPath, title }) {
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const res = await AuthService.profileGetService();
        if (res?.success) {
          updateUserDataAction(res?.data)
        }
      } catch (error) {
        console.log("errorrr", error);
      }
    }
    fetchProfileData()
  }, [])

  const handleLogout = () => {
    removeLocalStorageItem("admin-token");
    dispatch(logoutAction({}))
    setRedirectPath("/login");
  };

  const handleLinkClick = () => {
    setDropdownOpen(false); // Close the dropdown
  };

  return (
    <>
      <div className='header-sticky hstack py-2'>
        <h1 className="me-auto mb-0">{title}</h1>
        <Dropdown drop="up" className="justify-content-end d-none d-md-flex" show={dropdownOpen} onToggle={setDropdownOpen}>
          <Dropdown.Toggle className="px-0 border-0" variant="none" id="dropdown-basic">
            <Image
              // eslint-disable-next-line no-undef
              src={userData?.profile_image ? `${process.env.REACT_APP_BASE_URL}${userData?.profile_image}` : profile}
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = profilePlaceholder;
              }}
              width="40"
              height="40"
              className="rounded-circle me-1"
            />
            {userData?.first_name} {userData?.last_name}
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" className='py-0 profile-dropdown'>
            <Link className="px-2 py-1 text-decoration-none dropdown-item" to={"/admin/profile"} onClick={handleLinkClick}>
              <PiUser size={20} className="me-1" />
              <span>Profile</span>
            </Link>
            <Link className="px-2 py-1 text-decoration-none dropdown-item" to={"/admin/change-password"} onClick={handleLinkClick}>
              <PiPassword size={20} className="me-1" />
              <span>Change Password</span>
            </Link>
            <Link className="px-2 py-1 text-decoration-none dropdown-item" onClick={handleLogout}>
              <PiSignOut size={20} className="me-1" />
              <span>Logout</span>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <hr className='border-bottom horizontal-border opacity-100 border-0' />
    </>
  )
}
AdminHeader.propTypes = {
  setRedirectPath: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};