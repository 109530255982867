
import { createSlice } from '@reduxjs/toolkit';


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: {}
  },
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    updateUserDataAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
  },
});

export const { loginAction, updateUserDataAction, logoutAction } = authSlice.actions;

export default authSlice.reducer;

