import { getLocalStorageItem } from "../helper/commonHelper";

function authDriver(route, userData, pathname) {
  const token = getLocalStorageItem("admin-token")

  try {
    const checkData = token;
    let user = "";
    if (pathname.search("admin") >= 0) {
      user = "admin";
    } else {
      user = "user";
    }

    const userCheck = userData?.userRole ?? user;
    if (userCheck === "user" && route?.common === true) {
      return true;
    } else if (route.private && userData?.userRole === user) {
      // ********For secure route**************
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!checkData) {
        return true;
      } else {
        return false;
      }
    } else {
      // **Non secure route****
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!checkData) {
        return false;
      }
      if (route.private === false) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
}

export default authDriver;
