/* eslint-disable react/display-name */
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormInput } from "../UiElement";
import { Link } from "react-router-dom";


export const ForgotPasswordForm = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ handleSubmit, register, errors, onSubmit, isLoading }) => {
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="Email address"
            type="email"
            placeholder="Enter your email"
            name="email"
            register={register}
            errors={errors}
            col={12}
          />
          <div className="hstack  justify-content-between" >
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  Submit{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Link className="text-decoration-none" to={"/login"} >Login</Link>
          </div>
        </Form>
      </>
    );
  }
);
