export const ManageCmsAPI = {
  getCms: {
    url: '/admin/cms',
    method: 'GET',
  },
  getCmsId: {
    url: '/cms',
    method: 'GET',
  },
  createCms: {
    url: '/api/admin/cms',
    method: 'POST',
  },
  editCms: (id) => ({
    url: `/admin/cms/${id}`,
    method: 'PUT',
  }),
};
