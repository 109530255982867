import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ImageMultiUploader = ({
  selectedImage,
  setSelectedImage,
  useFormMethods,
}) => {
  // eslint-disable-next-line react/prop-types
  const { errors, setValue, watch } = useFormMethods;
  const [previewImages, setPreviewImages] = useState([]);

  // Handle new image uploads
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setPreviewImages((prevImages) => [...prevImages, ...newImages]);
    const currentCertification = Array.isArray(watch().certification) ? watch().certification : [];
    setValue("certification", [...currentCertification, ...files]);
  };

  // Remove a new uploaded image
  const handleRemoveNewImage = (index) => {
    const filteredDocuments = watch().certification.filter(doc => doc.id === undefined);
    const otherDocument = watch().certification.filter(doc => doc.id !== undefined);
    setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index));
    const currentCertification = Array.isArray(filteredDocuments) ? filteredDocuments : [];
    const updatedCertification = currentCertification.filter((_, i) => i !== index);
    setValue("certification", [...otherDocument, ...updatedCertification]);
  };

  // Remove an existing backend image
  const handleRemoveExistingImage = (imageId) => {
    const filteredDocuments = watch().certification.filter(doc => doc.id === undefined);
    const otherDocument = watch().certification.filter(doc => doc.id !== undefined);
    setSelectedImage((prevImages) =>
      prevImages.filter((img) => img.id !== imageId)
    );
    const currentCertification = Array.isArray(otherDocument) ? otherDocument : [];
    const updatedCertification = currentCertification.filter((doc) => doc.id !== imageId);
    setValue("certification", [...updatedCertification, ...filteredDocuments]);
  };


  useEffect(() => {
    setSelectedImage(selectedImage); // Update existing images when initialImages changes
  }, [selectedImage, setSelectedImage]);

  return (
    <div className="mb-3">
      <h4>Images:</h4>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }} >
        {selectedImage &&
          selectedImage?.map((image,index) => (
            <div key={image.id} style={{ position: "relative" }}>
              <img
                // eslint-disable-next-line no-undef
                src={`${process.env.REACT_APP_BASE_URL}${image.document_link}`}
                alt="existing"
                style={{ width: 100, height: 100, objectFit: "cover" }}
                key={index}
              />
              <button
                type="button"
                onClick={() => handleRemoveExistingImage(image.id)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  border: "none",
                }}
              >
                &times;
              </button>
            </div>
          ))}

        {/* New Image Uploads */}
        {previewImages.map((image, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={image.url}
              alt="preview"
              style={{ width: 100, height: 100, objectFit: "cover" }}
            />
            <button
              type="button"
              onClick={() => handleRemoveNewImage(index)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "red",
                color: "white",
                borderRadius: "50%",
                border: "none",
                marginBottom : "10px"
              }}
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      {/* File Input for New Images */}
      <div style={{ marginTop: "10px" }}>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageUpload}
        />
        {errors?.certification && !watch()?.certification?.length > 0 ? <p className='text-danger'>{errors?.certification?.message}</p> : ""}

      </div>
    </div>
  );
};


////// solve prop issue which getting via eslint ////
ImageMultiUploader.propTypes = {
  selectedImage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      document_link: PropTypes.string.isRequired,
    })
  ),
  setSelectedImage: PropTypes.func.isRequired,
  useFormMethods: PropTypes.shape({
    errors: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
  }).isRequired,
};

export default ImageMultiUploader;
