import React from "react";
import { PiNotebook } from "react-icons/pi";
import { Catelogs } from "../../../pages";


export default function route() {
  return [
    {
      path: "/admin/catalogs",
      name: "Catalogs",
      key: "/admin/catalogs",
      private: true,
      belongsToSidebar: true,
      icon: <PiNotebook size={32} />

      ,
      element: <Catelogs />
      // children: [
      //   {
      //     path: "/admin/treatment/treatments",
      //     name: "Treatment",
      //     key: "/admin/treatment/treatments",
      //     private: true,
      //     belongsToSidebar: true,
      //     icon: <PiBriefcase size={24} />,
      //     element: <Treatments />,
      //   },
      //   {
      //     path: "/admin/treatment/category",
      //     name: "Treatment Category",
      //     key: "/admin/treatment/category",
      //     private: true,
      //     belongsToSidebar: true,
      //     icon: <PiBriefcase size={24} />,
      //     element: <TreatmentsCategroy />,
      //   }
      // ]
    },
  ];
}
