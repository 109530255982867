import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import React,{ useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';


function CommonTable({
  columns = [],
  data = [],
  excludes = [],
  getfilter,
  isLink,
  parentLink = "/",
  keyValue,
  rowclick,
  customClass,
  loading = false,
}) {

  const navigate = useNavigate()

  // Use the state and functions returned from useTable to build your UI
  const [filter, setFilter] = useState({
    sortBy: '',
    sortKey: ''
  })
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className={`custom-table table-responsive my-0  ${customClass} `}>
      <table className='table table-hover mb-0'>

        <thead
          style={{
            pointerEvents: `${data?.length ? 'initial' : 'none'
              }`
          }}
          className="rounded-start-4"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={`text-left ${excludes.includes(header.id)
                    ? ['nofilter']
                    : ''
                    }`}
                  style={{ minWidth: '120px' }}
                  key={header.id}
                  onClick={() => {
                    if (!excludes.includes(header.id)) {
                      setFilter((pre) => {
                        if (
                          pre.sortKey ===
                          header.id
                        ) {
                          return {
                            ...pre,
                            sortBy:
                              pre.sortBy === 1
                                ? -1
                                : 1
                          }
                        }
                        return {
                          ...pre,
                          sortKey: header.id,
                          sortBy: 1
                        }
                      })
                      if (
                        typeof getfilter ===
                        'function'
                      ) {
                        getfilter(
                          filter.sortKey ===
                            header.id
                            ? {
                              ...filter,
                              sortBy:
                                filter.sortBy ===
                                  1
                                  ? -1
                                  : 1
                            }
                            : {
                              ...filter,
                              sortKey:
                                header.id,
                              sortBy: 1
                            }
                        )
                      }
                    }
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef
                        .header,
                      header.getContext()
                    )}
                  <span className='sort-body'>
                    {filter.sortBy !== '' &&
                      filter.sortKey !== '' &&
                      filter.sortKey === header.id ? (
                      filter.sortBy === 1 ? (
                        <>
                          <i className='icon-backarrow active'></i>
                          <i className='icon-backarrow down'></i>
                        </>
                      ) : (
                        <>
                          <i className='icon-backarrow'></i>
                          <i className='icon-backarrow down active'></i>
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {loading ?
          <tbody>
            <tr>
              <td colSpan={100}>
                <div className="spinner-container">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          : data?.length > 0 ? (
            <>
              <tbody>
                {table.getRowModel().rows.map((row) => (

                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        onClick={() => {
                          if (
                            isLink &&
                            !rowclick?.includes(
                              cell.column?.id
                            )
                          ) {
                            navigate(
                              `${parentLink}/${row.original?.[keyValue]}`,
                            )
                            // navigate(
                            //     `${row.original?.[keyValue]}`
                            // )
                          }
                        }}
                        className={`text-left align-middle ${isLink
                          ? 'cursor-pointer'
                          : ''
                          }`}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </>
          )
            :
            <>
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className='data-not-found'>
                      No Data Available
                    </div>
                  </td>
                </tr>
              </tbody>
            </>
        }

      </table>
    </div>
  )
}

// Define prop types
CommonTable.propTypes = {
  columns: PropTypes.array.isRequired,  
  data: PropTypes.array,     
  excludes: PropTypes.array,             
  getfilter: PropTypes.func,             
  isLink: PropTypes.bool,                
  parentLink: PropTypes.string,          
  keyValue: PropTypes.string.isRequired, 
  rowclick: PropTypes.array,             
  innerClass: PropTypes.string,          
  customClass: PropTypes.string,         
  loading: PropTypes.bool,              
};

export default memo(CommonTable)

