export const ManageCatelogsAPI = {
  getCateglogs: {
    url: '/admin/catalogs',
    method: 'GET',
  },
  createCateglogs: {
    url: '/admin/catalogs',
    method: 'POST',
  },
  editCateglogs: (id) => ({
    url: `/admin/catalogs/${id}`,
    method: 'PUT',
  }),
  deleteCatelogsById: (id) => ({
    url: `/admin/catalogs/${id}`,
    method: 'DELETE',
  }),

};
