import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  password: yup.string().required('password is a required field'),
  email: yup.string().required('email is a required field'),
});

export const userSchema = (isDoctor, showSocialMediaFields) => {
  return yup.object().shape({
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is a required field'),
    password: !showSocialMediaFields?.isEdit
      ? yup
          .string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters long')
          .matches(
            /[a-z]/,
            'Password must contain at least one lowercase letter'
          )
          .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase letter'
          )
          .matches(/\d/, 'Password must contain at least one number')
          .matches(
            /[@$!%*?&#]/,
            'Password must contain at least one special character'
          )
      : yup.string().nullable(),
    first_name: yup
      .string()
      .required('First name is a required field')
      .min(2, 'First name must be at least 2 characters long')
      .matches(/^[a-zA-Z\s]*$/, 'First name cannot contain special characters'),
    last_name: yup
      .string()
      .required('Last name is a required field')
      .min(2, 'Last name must be at least 2 characters long')
      .matches(/^[a-zA-Z\s]*$/, 'Last name cannot contain special characters'),
    role_id: yup.string().required('Role is a required field'),
    weight: yup.string().when('role_id', {
      is: '3',
      then: schema => {
        return schema
          .required('Weight is required')
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            'Weight must be a valid number with up to two decimal places'
          );
      },
      otherwise: schema => {
        return schema.notRequired();
      },
    }),
    height: yup.string().when('role_id', {
      is: '3',
      then: schema =>
        schema
          .required('Height is required')
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            'Height must be a valid number with up to two decimal places'
          ),
      otherwise: schema => schema.notRequired(),
    }),

    // medical_problem: yup.string().when('role_id', {
    //   is: '3',
    //   then: schema => schema.required('Medical problem is required'),
    //   otherwise: schema => schema.notRequired(),
    // }),
    // result: yup
    //   .string()
    //   .when("role_id", {
    //     is: "3",
    //     then: (schema) => schema.required("Result is required"),
    //     otherwise: (schema) => schema.notRequired(),
    //   }),
    // surgeries_in_past: !isDoctor && yup
    //   .boolean()
    //   .when("role_id", {
    //     is: "3",
    //     then: (schema) => schema.required("Surgeries in past is required"),
    //     otherwise: (schema) => schema.notRequired(),
    //   }),
    taking_medication:
      !isDoctor &&
      yup.boolean().when('role_id', {
        is: '3',
        then: schema => schema.required('Taking medication field is required'),
        otherwise: schema => schema.notRequired(),
      }),
    smoke:
      !isDoctor &&
      yup.boolean().when('role_id', {
        is: '3',
        then: schema => schema.required('Smoke field is required'),
        otherwise: schema => schema.notRequired(),
      }),
    // taking_medication_description: yup.string()
    //   .when("role_id", {
    //     is: "3",
    //     then: (schema) => schema.required("Description of medication is required"),
    //     otherwise: (schema) => schema.notRequired(),
    //   }),

    // qualification: isDoctor
    //   ? yup.string().required('Qualification is required for doctors.')
    //   : yup.string().nullable(),

    qualification: isDoctor ? yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required('Value is required'),
          name: yup.string().required('Name is required'),
        })
      )
      .min(1, 'At least one treatment is required')
      .required('Treatment is required') : yup.array().nullable(),

    // office_hours: isDoctor
    //   ? yup.string().required("Office Hours is required for doctors.")
    //   : yup.string().nullable(),

    schedules: isDoctor
      ? yup
          .array()
          .of(
            yup.object().shape({
              day: yup.string().required('Day is required'),
              start: yup.string().required('Start time is required'),
              end: yup
                .string()
                .required('End time is required')
                .test(
                  'is-after-start',
                  'End time must be after start time',
                  function (value) {
                    const { start } = this.parent;
                    return start && value && value > start;
                  }
                ),
              isClose: yup.boolean().required(),
            })
          )
          .min(1, 'At least one schedule is required')
      : yup.array().nullable(),

    dob: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .when('role_id', {
        is: role_id => role_id !== '2',
        then: schema => schema.required('Date of birth is a required field'),
        otherwise: schema => schema.notRequired(),
      })
      .max(new Date(), 'Date of birth cannot be in the future'),

    gender: !isDoctor
      ? yup.string().required('Please select your gender.')
      : yup.string().nullable(),
    country: yup.string().required('Country is a required field'),
    state: yup.string().required('State is a required field'),
    // city: yup.string().required('City is a required field'),
    phone_number: isDoctor
      ? yup.string().required('Phone number is required for doctors.')
      : yup.string().nullable(),
    company_name: isDoctor
      ? yup.string().required('Company name is required for doctors.')
      : yup.string().nullable(),
    web_url: isDoctor
      ? yup
          .string()
          .url('Must be a valid URL')
          .required('Website URL is required for doctors.')
      : yup.string().nullable(),

    certification: isDoctor
      ? yup.mixed().test('file', 'You need to provide a image', value => {
          if (value?.length > 0) {
            return true;
          }
          return false;
        })
      : yup.string().nullable(),
    // certifications: isDoctor
    //   ? yup.array()
    //     .of(yup.mixed().required("Certification image is required"))
    //     .min(1, "Certification image is a required field")
    //     .required("Certification image is a required field")
    //   : yup.string().nullable(),
    address_1: isDoctor
      ? yup.string().required('Address Line 1 is required for doctors.')
      : yup.string().nullable(),
    // address_2: isDoctor
    //   ? yup.string().required("Address Line 2 is required for doctors.")
    //   : yup.string().nullable(),
    instagram_url:
      showSocialMediaFields?.isSocialMedia &&
      !showSocialMediaFields?.isInstagram
        ? yup
            .string()
            .url('Must be a valid Instagram URL')
            .required(
              'Instagram URL is required if you attach social media links.'
            )
        : yup.string().nullable(),

    tiktok_url:
      showSocialMediaFields?.isSocialMedia && !showSocialMediaFields?.isTiktok
        ? yup
            .string()
            .url('Must be a valid TikTok URL')
            .required(
              'TikTok URL is required if you attach social media links.'
            )
        : yup.string().nullable(),
  });
};

export const cmsSchema = yup.object().shape({
  title: yup.string().required('Title is a required field'),
  description: yup.string().required('Description is a required field'),
});

export const treatmentSchema = yup.object().shape({
  treatmentName: yup.string().required('Treatment Name is a required field'),
  treatmentBody: yup.string().required('Treatment Body is a required field'),
  gender: yup.string().required('Please select your gender.'),
});

export const treatmentCategorySchema = yup.object().shape({
  treatmentName: yup.string().required('Treatment Name is a required field'),
  treatmentCategory: yup
    .string()
    .required('Treatment Category is a required field'),
});

export const editProfileSchema = yup.object().shape({
  first_name: yup.string().required('First name is a required field'),
  last_name: yup.string().required('Last name is a required field'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is a required field'),
  profile_image: yup
    .array()
    .min(1, 'Profile image is a required field') // Ensure at least one file is selected
    .required('Profile image is a required field'),
});

export const technicalSchema = yup.object().shape({
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, 'Phone number is not valid'),
  email: yup.string().required('email is a required field'),
});

export const changePasswordSchema = yup.object().shape({
  old_password: yup.string().required('Current password is required'),
  new_password: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters long')
    .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
    .matches(/\d/, 'New password must contain at least one number')
    .matches(
      /[@$!%*?&#]/,
      'New password must contain at least one special character'
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

export const CatelogsSchema = yup.object().shape({
  catelogs_image: yup.mixed().test("file", "Catalogs image is a required field", (value) => {
    if (value.length > 0) {
      return true;
    }
    return false;
  }),
  catelog_name: yup.string().required("Catalogs name is a required field"),
  description: yup.string().required("Description is a required field"),
});

export const forgotSchema = yup.object().shape({
  email: yup.string().required('email is a required field'),
});

export const resetSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[@$!%*?&#]/,
      'Password must contain at least one special character'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your password'),
});
