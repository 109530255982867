import React from "react";
import { PiUser, PiUserList } from "react-icons/pi";
import { UserAddEdit, UserList, UserView, } from "../../../pages";


export default function route() {
  return [
    {
      path: "/admin/user",
      name: "User Management",
      key: "/admin/user",
      private: true,
      belongsToSidebar: true,
      icon: <PiUserList size={32} />

      ,
      element: <UserList />,
    },
    {
      path: "/admin/user/add",
      name: "Add Users",
      key: "/admin/user/add",
      private: true,
      belongsToSidebar: false,
      icon: <PiUser size={24} />,
      element: <UserAddEdit />,
    },
    {
      path: "/admin/user/:id",
      name: "Add Users",
      key: "/admin/user/:id",
      private: true,
      belongsToSidebar: false,
      icon: <PiUser size={24} />,
      element: <UserAddEdit />,
    },
    {
      path: "/admin/user/view/:id",
      name: "View Users",
      key: "/admin/user/view/:id",
      private: true,
      belongsToSidebar: false,
      icon: <PiUser size={24} />,
      element: <UserView />,
    },
  ]
}