
import { ManageTreatmentAPI, } from "../../apiendpoints";
import { APIrequest } from "../../utils";

export const ManageTreatmentService = {
  getTreatmentListService: async ({ queryParams }) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.getTreatments.method,
        url: ManageTreatmentAPI.getTreatments.url,
        queryParams
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  createTreatmentsService: async (bodyData) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.createTreatment.method,
        url: ManageTreatmentAPI.createTreatment.url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getTreatmentCategoriesListService: async ({ queryParams }) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.getTreatmentsCategory.method,
        url: ManageTreatmentAPI.getTreatmentsCategory.url,
        queryParams
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  createTreatmentsCategoriesService: async (bodyData) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.createTreatmentsCategory.method,
        url: ManageTreatmentAPI.createTreatmentsCategory.url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  editTreatmentService: async (id, bodyData) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.updateTreatment(id).method,
        url: ManageTreatmentAPI.updateTreatment(id).url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  editTreatmentCategoryService: async (id, bodyData) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.updateTreatmentsCategory(id).method,
        url: ManageTreatmentAPI.updateTreatmentsCategory(id).url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  deleteTreatmentService: async (id, isCategories) => {
    try {
      const response = await APIrequest({
        method: ManageTreatmentAPI.deleteTreatmentsById(id, isCategories).method,
        url: ManageTreatmentAPI.deleteTreatmentsById(id, isCategories).url,
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },



};
