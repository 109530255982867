
import React from 'react';
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi';
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'; 


const CommonPagination = ({
  onPageChange,
  pageCount,
  activePage,
  className
}) => {

  return (
    <>
      <ReactPaginate
        previousLabel={<PiCaretLeft />}
        nextLabel={<PiCaretRight />}
        breakLabel={'...'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(e) => onPageChange(e.selected + 1)}
        containerClassName={`pagination ${className}`}  // Apply Bootstrap pagination styles
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        forcePage={activePage - 1}
      />
    </>
  )
}


CommonPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired, 
  pageCount: PropTypes.number.isRequired, 
  activePage: PropTypes.number.isRequired, 
  className: PropTypes.string, 
};


export default CommonPagination
