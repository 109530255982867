// import { MdOutlineCategory } from "react-icons/md";


export default function route() {
  return [
    {
      // path: "/admin/treatment",
      // name: "Treatment Management",
      // key: "/admin/treatment",
      // private: true,
      // belongsToSidebar: true,
      // icon: <PiFirstAidKit size={32} />,
      // children: [
      //   {
      //     path: "/admin/treatment/treatments",
      //     name: "Treatment",
      //     key: "/admin/treatment/treatments",
      //     private: true,
      //     belongsToSidebar: true,
      //     icon: <PiHeartbeat size={32} />,
      //     element: <Treatments />,
      //   },
      //   // {
      //   //   path: "/admin/treatment/category",
      //   //   name: "Treatment Category",
      //   //   key: "/admin/treatment/category",
      //   //   private: true,
      //   //   belongsToSidebar: true,
      //   //   icon: <MdOutlineCategory size={24} />,
      //   //   element: <TreatmentsCategroy />,
      //   // }
      // ]
    },
  ];
}
