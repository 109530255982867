export const ManageUserAPI = {
  getUser: {
    url: '/admin/users',
    method: 'GET',
  },
  approveUser: (id) => ({
    url: `/admin/admin-approval/${id}`,
    method: 'PATCH',
  }),
  createUser: {
    url: '/admin/users',
    method: 'POST',
  },
  getUserById: (id) => ({
    url: `/admin/users/${id}`,
    method: 'GET',
  }),
  updateUserById: (id) => ({
    url: `/admin/users/${id}`,
    method: 'PUT',
  }),
  deleteUserById: (id) => ({
    url: `/admin/users/${id}`,
    method: 'DELETE',
  }),
};
