export const statusFormatter = (rowData, statusType) => {
  let buttonColor = "";
  let buttonText = "";

  if (rowData?.role_id === 2 || rowData?.role_id === "2") {
    // For role_id 2, handle statusType cases
    switch (statusType) {
      case 0:
        buttonText = 'Pending';
        buttonColor = 'gray';
        break;
      case 1:
        buttonText = 'Approved';
        buttonColor = 'green';
        break;
      case 2:
        buttonText = 'Rejected';
        buttonColor = 'red';
        break;
      default:
        buttonText = 'Unknown';
        buttonColor = 'black';
        break;
    }
  } else {
    // For other role_ids, handle statusType cases
    switch (statusType) {
      case 0:
        buttonText = 'Active';
        buttonColor = 'green';
        break;
      default:
        buttonText = 'Unknown';
        buttonColor = 'black';
        break;
    }
  }

  // Return the button for both cases
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <button
      style={{
        backgroundColor: buttonColor,
        color: 'white',
        border: 'none',
        padding: '5px', // Add padding here
        borderRadius: '5px',
        cursor: 'pointer',
        width: '80px'
      }}
    >
      {buttonText}
    </button>
  );
};



export const getTitleFromPath = (path) => {
  const parts = path.split('/');
  const result = `/${parts[1]}/${parts[2]}`;


  const obj = {
    "/admin/user": "User Management",
    "/admin/cms": "Content Management",
    "/admin/communication": "Communication",
    "/admin/technical-support": "Technical Support",
    "/admin/catalogs": "Catalogs",
    "/admin/treatment": "Treatment Management",
    "/admin/dashboard": "Dashboard",
    "/admin/change-password": "Change Password",
    "/admin/profile": "Profile"

  }

  return obj[result]

}