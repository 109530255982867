import React from "react";
import PropTypes from "prop-types";
import { Button, Form, } from "react-bootstrap";
import { useForm } from "react-hook-form";


export const UserFilterForm = ({
  onSubmit,
  searchValue,
  setSearchValue,
  setSelectedRole,
  selectedRole,
  fetchUserList
}) => {
  const { register, handleSubmit, reset } = useForm();

  // Handle reset button click
  const handleReset = () => {
    reset();
    setSearchValue("");
    setSelectedRole("");
    fetchUserList("", "")
  };
  const roleOptions = [
    { name: "Patient", isoCode: 3 },
    { name: "Doctor", isoCode: 2 },
  ];





  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center">
      <div className="align-items-center d-flex gap-lg-4 gap-3 flex-wrap">
        {/* User Name Label and Input */}
        <div className="align-items-center d-flex gap-2">
          <div>
            <Form.Label className="mb-0">Name</Form.Label>
          </div>
          <div>
            <Form.Control
              type="text"
              placeholder="Search..."
              {...register("search")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        {/* Role Label and Select */}
        <div className="align-items-center d-flex gap-2">
          <div>
            <Form.Label className="mb-0">Role</Form.Label>
          </div>
          <div>
            <Form.Select
              {...register("role")}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="">Select a role</option>
              {roleOptions.map((role, idx) => (
                <option key={idx} value={role?.isoCode}>
                  {role.name}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        {/* Buttons */}
        <div className="d-flex gap-2">
          <Button type="submit" variant="primary">
            Search
          </Button>
          <Button variant="none" className="border" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>

    </Form>
  );
};

UserFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  selectedRole: PropTypes.string.isRequired,
  fetchUserList: PropTypes.func.isRequired,
};


