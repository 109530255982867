import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AdminHeader, AdminSidebar } from '../../components'
import "../../styles/sass/sidebar.scss"
import AppLayout from '../appLayout'
import { moduleRoutesList } from '../../routes'
import { Card, CardBody, Container } from 'react-bootstrap'
import { getTitleFromPath } from '../../utils/common.utils'


export default function AdminPrivateLayout() {
  const navigate = useNavigate()
  const location = useLocation()
  const [redirectpath, setRedirectPath] = useState("");

  const routes = moduleRoutesList().admin;

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [navigate, redirectpath]);


  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath} >
        <div className="layout-container flex-md-row flex-column">
          <AdminSidebar setRedirectPath={setRedirectPath} routes={routes} className="sidebar" />
          <Container fluid className='gx-md-8 overflow-y-auto'>
            <AdminHeader setRedirectPath={setRedirectPath} title={getTitleFromPath(location?.pathname)} />
            <div className='my-5 '>
              <Card className='border-0 shadow'>
                <CardBody>
                  <Outlet />
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </AppLayout>
    </>
  )
}
