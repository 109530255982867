import Swal from 'sweetalert2';

const ShowAlert = async (title, text, icon, textConfirmBtn = "Yes", textCancelBtn = "No") => {
  const result = await Swal.fire({
    title: title || 'Are you sure?',
    text: text || 'You won\'t be able to revert this!',
    icon: icon || 'warning',
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: textConfirmBtn,
    cancelButtonText: textCancelBtn,
    allowOutsideClick: false,
  });


  if (result.isConfirmed) {
    return 'approved';
  } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
    return 'rejected';
  } else {
    return 'closed';
  }
};

export default ShowAlert;
