import axios from "axios";
import { getLocalStorageItem, removeLocalStorageItem } from "../helper/commonHelper";
import { showAlertMessage } from "../components";

const APIrequest = async ({
  method,
  url,
  baseURL,
  queryParams,
  bodyData,
  cancelFunction,
  formHeaders,
  removeHeaders,
  token = "",
  fromData
}) => {
  const apiToken = token !== "" ? token : getLocalStorageItem("admin-token");

  try {
    const axiosConfig = {
      method: method || "GET",
      // baseURL: `/api`,
      // eslint-disable-next-line no-undef
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        ...(fromData
          ? { "content-type": "multipart/form-data" }
          : { "content-type": "application/json" }),
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (formHeaders) {
      axiosConfig.headers = { ...axiosConfig.headers, ...formHeaders };
    }

    if (baseURL) {
      axiosConfig.baseURL = baseURL;
    }

    if (url) {
      axiosConfig.url = url;
    }

    if (queryParams) {
      const queryParamsPayload = {};
      for (const key in queryParams) {
        if (Object.hasOwnProperty.call(queryParams, key)) {
          let element = queryParams[key];
          if (typeof element === "string") {
            element = element.trim();
          }
          if (!["", null, undefined, NaN].includes(element)) {
            queryParamsPayload[key] = element;
          }
        }
      }
      axiosConfig.params = queryParamsPayload;
    }

    if (bodyData) {
      const bodyPayload = {};
      for (const key in bodyData) {
        if (Object.hasOwnProperty.call(bodyData, key)) {
          let element = bodyData[key];
          if (typeof element === "string") {
            element = element.trim();
          }
          if (![null, undefined, NaN].includes(element)) {
            bodyPayload[key] = element;
          }
        }
      }
      axiosConfig.data = bodyPayload;
    } else {
      axiosConfig.data = fromData;
    }

    if (cancelFunction) {
      axiosConfig.cancelToken = new axios.CancelToken((cancel) => {
        cancelFunction(cancel);
      });
    }

    if (removeHeaders) {
      delete axiosConfig.headers;
    }

    if (apiToken) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        authorization: `Bearer ${apiToken}`,
      };
    }

    const res = await axios(axiosConfig);
    return res.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error("API request canceled");
    } else {
      const errorRes = error.response;

      if (errorRes && errorRes.status) {
        const { status, data } = errorRes;

        switch (status) {
          case 401:
            // Unauthorized - Handle based on apiToken
            if (apiToken) {
              removeLocalStorageItem("admin-token");
              window.location.replace("/login");
            } else {
              showAlertMessage(errorRes?.data?.message || "Unauthorized access", "error");
            }
            break;

          case 403:
            // Forbidden - Remove token and redirect to login
            // removeLocalStorageItem("admin-token");
            showAlertMessage("You do not have permission to access this resource.", "error");
            break;

          case 404:
            // Not found - Show a generic error message
            showAlertMessage("Something went wrong at the backend.", "error");
            break;

          case 500:
            // Server error - Remove token and redirect to login
            // removeLocalStorageItem("admin-token");
            // window.location.replace("/login");
            showAlertMessage(data.message || "An error occurred. Please try again later.", "error");
            break;

          case 400:
            // Bad request - Show the error message from the API
            showAlertMessage(data.message || "Invalid request.", "error");
            break;

          default:
            // Handle other status codes if needed
            showAlertMessage(data.message || "An error occurred. Please try again.", "error");
            break;
        }
      } else {
        // Handle the case when there's no error response (e.g., network issues)
        showAlertMessage("A network error occurred. Please check your connection.", "error");
      }

      return null;
    }
  }
};

export default APIrequest;


