import loadable from "@loadable/component";

export * from "./admin/TreatmentManagement/index"
export const NotFound = loadable(() => import("./notfound")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
);
export const AdminLogin = loadable(() => import("../pages/admin/Login/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
);
export const ForgotPassword = loadable(
  () => import("../pages/admin/ForgotPassword"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
);
export const Dashboard = loadable(() => import("./admin/Dashbaord/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
);

export const UserList = loadable(
  () => import("./admin/ManageUser/userList/index"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
);
export const UserAddEdit = loadable(
  () => import("./admin/ManageUser/userAddEdit/index"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
);
export const CommunicationAndEngagement = loadable(
  () => import("./admin/CommunicationAndEngagement/index"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
);
export const Profile = loadable(() => import("./admin/Profile/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
);
export const ManageCms = loadable(() => import("./admin/ManageCms/CmsList/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
);

export const AddEditCms = loadable(() => import("./admin/ManageCms/AddEditCms/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
)

export const TechnicalAndSupport = loadable(
  () => import("./admin/TechnicalAndSupport/index"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
);

export const UserView = loadable(() => import("./admin/ManageUser/userView/index"),
  // {
  //   fallback: <div>Loading...</div>,
  // }
)
export const ChangePassword = loadable(() => import("./admin/ChangePassword/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
)

export const Catelogs = loadable(() => import("./admin/Categlos/index")
  // , {
  //   fallback: <div>Loading...</div>,
  // }
)
export const ResetPassword = loadable(() => import("./admin/ResetPassword/index"))