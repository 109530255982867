// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
  display: flex !important;
  height: 100vh !important;
}
@media (min-width: 768px) {
  .layout-container .sidebar {
    width: 406px !important;
    background-color: #f4f4f4 !important;
  }
}

.sidebar .dropup .dropdown-toggle::after {
  display: none !important;
}
.sidebar .dropup .dropdown-toggle .arrow {
  transition: 0.2s ease-in-out !important;
}
.sidebar .dropup .dropdown-toggle.show .arrow {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .brand-logo {
    background-color: #002B5C;
  }
}
.sidebar-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #002B5C;
}
.sidebar-link:hover {
  background: linear-gradient(90deg, #FEA491 10%, #AD54E4 100%);
  color: white;
}
.sidebar-link.active {
  background: linear-gradient(90deg, #FEA491 10%, #AD54E4 100%);
  color: #fff !important;
}

.sidebar-link-sub-menu {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar-link-sub-menu:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.sidebar-link-sub-menu.active {
  border: black 1px solid;
}

@media screen and (min-width: 768px) {
  .sidebar {
    max-width: 300px;
    height: 100vh;
    overflow-y: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/sass/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,wBAAA;AACF;AAEI;EADF;IAEI,uBAAA;IACA,oCAAA;EACJ;AACF;;AAMI;EACE,wBAAA;AAHN;AAKI;EACE,uCAAA;AAHN;AAMM;EACE,yBAAA;AAJR;;AAYA;EACA;IACE,yBAAA;EATA;AACF;AAaA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AAXF;AAYE;EACE,6DAAA;EACA,YAAA;AAVJ;AAYE;EACE,6DAAA;EACA,sBAAA;AAVJ;;AAcA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAXF;AAYE;EACE,sCAAA;AAVJ;AAYE;EACE,uBAAA;AAVJ;;AAeA;EACE;IACE,gBAAA;IACA,aAAA;IACA,gBAAA;EAZF;AACF","sourcesContent":[".layout-container {\n  display: flex !important;\n  height: 100vh !important; // Adjust as needed for your layout\n\n  .sidebar {\n    @media (min-width: 768px) {\n      width: 406px !important ; // Adjust the width to your preference\n      background-color: #f4f4f4 !important; // Example background color\n    }\n    // Add more sidebar-specific styles here\n  }\n}\n.sidebar{\n.dropup{\n  .dropdown-toggle{\n    &::after{\n      display: none !important;\n    }\n    .arrow{\n      transition: .2s ease-in-out !important;\n    }\n    &.show{\n      .arrow{\n        transform: rotate(180deg);\n      }\n    }\n  }\n}\n}\n\n\n@media (min-width: 768px) {\n.brand-logo{\n  background-color: #002B5C;\n}\n}\n\n\n.sidebar-link{\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #002B5C;\n  &:hover{\n    background: linear-gradient(90deg, #FEA491 10%, #AD54E4 100%);\n    color: white;\n  }\n  &.active{\n    background: linear-gradient(90deg, #FEA491 10%, #AD54E4 100%);\n    color: #fff !important;\n  }\n}\n\n.sidebar-link-sub-menu{\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  &:hover{\n    background-color: rgba(0, 0, 0, 0.075);\n  }\n  &.active{\n    border: black 1px solid;\n    // color: #fff !important;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .sidebar{\n    max-width: 300px;\n    height: 100vh;\n    overflow-y: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
