import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

const BreadCrumb = ({ breadcrumbData }) => {

  return (
    <>
      <nav aria-label="breadcrumb" className='p-1 rounded-3 mb-3'>
        <ol className="breadcrumb text-black mb-0">
          {breadcrumbData && breadcrumbData?.map((data, index) => {
            return (
              data?.url ?
                <li className="breadcrumb-item font-bold" key={index} ><Link to={data?.url} className="text-decoration-none fw-bold ">{data?.title}</Link></li>
                :
                <li className="breadcrumb-item active" aria-current="page" key={index}>{data?.title}</li>
            )
          })}
        </ol>
      </nav>
    </>
  )
}

BreadCrumb.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired, 
      url: PropTypes.string,             
    })
  ).isRequired, 
};

export default BreadCrumb