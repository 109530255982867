export const ManageTreatmentAPI = {
  getTreatments: {
    url: '/admin/treatments',
    method: 'GET',
  },
  createTreatment: {
    url: '/admin/treatments',
    method: 'POST',
  },
  updateTreatment: (id) => ({
    url: `/admin/treatments/${id}`,
    method: 'PUT',
  }),
  getTreatmentsCategory: {
    url: '/admin/treatment-categories',
    method: 'GET',
  },
  createTreatmentsCategory: {
    url: '/admin/treatment-categories',
    method: 'POST',
  },
  updateTreatmentsCategory: (id) => ({
    url: `/admin/treatment-categories/${id}`,
    method: 'PUT',
  }),
  deleteTreatmentsById: (id, isCategories) => ({
    url: isCategories ? `/admin/treatment-categories/${id}` : `/admin/treatments/${id}`,
    method: 'DELETE',
  }),

};
