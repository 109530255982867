/* eslint-disable react/prop-types */
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// eslint-disable-next-line react/prop-types
const RadioInput = ({label,options,register,name,validation,errors,col = 6,selectedValue = null,isLoading}) => {
  return (
    <Col lg={col}>
      <Form.Group className="mb-3" controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label>
        <Row>
          {isLoading
            ? (
              // Show skeletons for each option while loading
              Array(2).fill().map((_, i) => (
                <Col xs={"auto"} key={i}>
                  <Skeleton width={150} height={20} />
                </Col>
              ))
            ) : (
              options?.map((option) => (
                <Col xs={"auto"} key={option.value}>
                  <Form.Check
                    type="radio"
                    label={option.label}
                    value={option.value}
                    {...register(name, validation)}
                    defaultChecked={selectedValue === option.value}
                    isInvalid={!!errors[name]}
                    inline
                  />
                </Col>
              ))
            )}
        </Row>

        {!isLoading && errors[name] && (
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  );
};

export default RadioInput;
