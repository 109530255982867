import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PiArrowLeftBold } from 'react-icons/pi';
import PropTypes from 'prop-types'; 

const BackArrow = ({ customStyle }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="hstack" style={{ cursor: 'pointer', ...customStyle }} onClick={handleBack}>
      <PiArrowLeftBold className="me-1" size={16} style={{ marginTop: '-2px' }} />
      <span>Back</span>
    </div>
  );
};

BackArrow.propTypes = {
  customStyle: PropTypes.object, 
};

export default BackArrow;
