import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { PiCaretDown, PiList, PiSignOut, PiUser } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { beautyAppLogo, profile } from "../../../styles/image";
import { getSideBarData, removeLocalStorageItem } from "../../../helper/commonHelper";
import { logoutAction } from "../../../redux/authslice";

const AdminSidebar = ({ setRedirectPath, routes }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const [routeSideBarList, setRoutesideBarList] = useState([]);
  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (routes && routes instanceof Array) {
      setRoutesideBarList(getSideBarData(routes));
    }
  }, [routes]);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeRoute = routeSideBarList.find(item =>
      item.path === currentPath ||
      (item.children && item.children.some(child => child.path === currentPath))
    );
    if (activeRoute) {
      setOpenMenu(activeRoute?.path);
      const activeChild = activeRoute.children?.find(child => child.path === currentPath);

      if (activeChild) {
        setOpenSubMenu(activeChild.key);
      } else {
        setOpenSubMenu(null);
      }
    } else {
      setOpenMenu(null);
      setOpenSubMenu(null);
    }
  }, [location.pathname, routeSideBarList]);

  const handleToggleMenu = (key) => {
    if (openMenu === key) {
      setOpenMenu(null);
      setOpenSubMenu(null);
    } else {
      setOpenMenu(key);
      setOpenSubMenu(null);
    }
  };

  const handleToggleSubMenu = (key) => {
    setOpenSubMenu(openSubMenu === key ? null : key);
  };

  const handleLogout = () => {
    removeLocalStorageItem("admin-token");
    dispatch(logoutAction({}))
    setRedirectPath("/login");
  };

  return (
    <>
      <Navbar expand="md" className="bg-body-tertiary border-end w-100 sidebar py-md-0">
        <Container fluid className="flex-md-column align-items-start justify-content-start h-100 gx-md-0">
          <div className="hstack w-100 px-md-3 py-2 justify-content-md-center header-sticky bg-white border-bottom">
            <NavLink to={"/admin/dashboard"}>
              <Image src={beautyAppLogo} width="auto" height={54} />
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto shadow-none px-1">
              <PiList />
            </Navbar.Toggle>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto shadow-none" /> */}
          </div>
          <Navbar.Collapse className="flex-md-column w-100 py-2" id="basic-navbar-nav">
            <Nav className="me-auto flex-column w-100 h-100">
              {routeSideBarList.map((item, i) => (
                <div key={i} className="mb-1">
                  {item.children && item.children.length > 0 ? (
                    <>
                      <div
                        className={`px-2 py-2 w-100 sidebar-link text-decoration-none ${openMenu === item.key ? 'active' : ''}`}
                        onClick={() => handleToggleMenu(item.key)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item?.icon}
                        <span>{item.label}</span>
                        <PiCaretDown className="ms-auto" />
                      </div>
                      {openMenu === item.key && (
                        <div className="sub-menu">
                          {item.children.map((child, j) => (
                            <NavLink
                              key={j}
                              className={`border-0 p-2 w-100 sidebar-link-sub-menu text-decoration-none ps-5 ${openSubMenu === child.key ? "active" : ""}`}
                              to={child.path}
                              onClick={() => handleToggleSubMenu(child.key)}
                            >
                              {child?.icon}

                              <span>{child.label}</span>
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <NavLink
                      className={`px-2 py-2 w-100 sidebar-link text-decoration-none ${openMenu === item.key ? 'active' : ''}`}
                      to={item.path}
                      onClick={() => handleToggleMenu(item.key)}
                    >
                      {item?.icon}
                      <span>{item.label}</span>
                    </NavLink>
                  )}
                </div>
              ))}

              <Dropdown drop="up" className="mx-3 py-2 mt-auto d-block d-md-none">
                <Dropdown.Toggle className="px-0 border-0 w-100 hstack" variant="none" id="dropdown-basic">
                  <Image
                    // eslint-disable-next-line no-undef
                    src={userData?.profile_image ? `${process.env.REACT_APP_BASE_URL}${userData?.profile_image}` : profile}
                    width="40"
                    height="40"
                    className="rounded-circle me-1"
                  />
                  {userData?.first_name} {userData?.last_name}
                  <PiCaretDown className="text-secondary ms-auto arrow" size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Link className="px-2 py-1 text-decoration-none dropdown-item" to={"/admin/profile"}>
                    <PiUser size={20} className="me-1" />
                    <span>Profile</span>
                  </Link>
                  <Link className="px-2 py-1 text-decoration-none dropdown-item" to={"/admin/change-password"}>
                    <PiUser size={20} className="me-1" />
                    <span>Change Password</span>
                  </Link>
                  <Link className="px-2 py-1 text-decoration-none dropdown-item" onClick={handleLogout}>
                    <PiSignOut size={20} className="me-1" />
                    <span>Logout</span>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};


////// solve prop issue which getting via eslint ////
AdminSidebar.propTypes = {
  setRedirectPath: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element, 
      key: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          icon: PropTypes.element,
          key: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};


export default AdminSidebar;

