/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "react-loading-skeleton/dist/skeleton.css"; // optional for styles

const FormInput = ({
  label,
  type = "text",
  placeholder,
  register,
  name,
  validation,
  errors,
  col = 6,
  isLoading = false, // Add isLoading prop to control loading state
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Col lg={col}>
      <Form.Group className="mb-3" controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label> {/* Show Skeleton for label */}

        {type === "textarea" ? (
          isLoading ? (
            <Skeleton height={150} />
          ) : (
            <Form.Control
              as="textarea"
              rows={12}
              placeholder={placeholder}
              {...register(name, validation)}
              isInvalid={!!errors[name]}
            />
          )
        ) : (
          <>
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <InputGroup>
                <Form.Control
                  type={showPassword && type === "password" ? "text" : type} // Toggle between 'text' and 'password'
                  placeholder={placeholder}
                  {...register(name, validation)}
                  isInvalid={!!errors[name]} // Pass validation status
                  {...rest}
                />
                {type === "password" && (
                  <InputGroup.Text onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                )}
              </InputGroup>
            )}
            {/* Move Form.Control.Feedback outside InputGroup */}
            {errors[name] && !isLoading && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors[name]?.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      </Form.Group>
    </Col>
  );
};

export default FormInput;
