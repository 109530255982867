/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


// eslint-disable-next-line react/prop-types
const DatePicker = ({label,name,register,errors,defaultValue,isLoading,onChange,setValue,clearErrors}) => {
  const formattedDefaultValue = defaultValue
    ? moment(defaultValue, 'DD/MM/YYYY').format('YYYY-MM-DD') // converting 'DD/MM/YYYY' to 'YYYY-MM-DD'
    : '';
  useEffect(() => {
    register(name);
  }, [register, name]);
  const today = moment().format('YYYY-MM-DD');

  return (
    <Col lg="6">
      <Form.Group className="mb-3" controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label>

        {isLoading ? (
          <Skeleton height={38} />
        ) : (
          <Form.Control
            type="date"
            {...register(name)}
            max={today}
            defaultValue={formattedDefaultValue}
            onChange={(event) => {
              const dateValue = event.target.value;
              const formattedDate = moment(dateValue, 'YYYY-MM-DD').format('DD/MM/YYYY');
              clearErrors(name)
              if (onChange) {
                setValue(name, formattedDate);
              }
            }}
            isInvalid={!!errors[name]}
          />
        )}

        {errors[name] && !isLoading && (
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  );
};

export default DatePicker;
