/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editorConfiguration } from '../../../utils/editorConfigration';
import { Col, Form } from 'react-bootstrap';

const TextEditor = ({ register, setValue, name, value = "", errors, clearErrors, label, col = 12 }) => {
  useEffect(() => {
    if (register && name) {
      register(name);  // Register the field with react-hook-form
    }
  }, [register, name]);

  // Ensure value is always a string (use empty string if undefined or null)
  const editorValue = value ?? "";

  return (
    <Col lg={col}>
      <Form.Group className="mb-3" controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label>
        <CKEditor
          editor={ClassicEditor}
          data={editorValue}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue(name, data);  // Set the value for this field
            clearErrors(name);  // Clear the error if any
          }}
          config={editorConfiguration}
        />
        {errors[name] && (
          <div className="invalid-feedback d-block">
            {errors[name]?.message}
          </div>
        )}
      </Form.Group>
    </Col>
  );
};

export default TextEditor;
