import moment from "moment";
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const getItem = (path, label, key, icon, children) => {
  if (children) {
    return { label, key, icon, children, path };
  }
  return { label, key, icon, path };
};

export const getSideBarData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const truncateString = (str, numWords) => {
  const words = str.split(' ');
  if (words.length <= numWords) {
    return str;
  }
  return words.slice(0, numWords).join(' ') + '...';
}


export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error setting ${key} in localStorage:`, error);
  }
}

export const getLocalStorageItem = (key) => {
  try {
    const token = localStorage.getItem(key);
    return token;
  } catch (error) {
    console.error(`Error setting ${key} in localStorage:`, error);
  }
}

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error setting ${key} in localStorage:`, error);
  }
}
export const firstLatterCapital = (word) => {
  return word ? word[0].toUpperCase() + word.slice(1) : "-";
}
export const fullNameFirstCapLatter = (first, last) => {
  return `${firstLatterCapital(first)} ${firstLatterCapital(last)}`
}
export const dateFormatter = (date, format) => {
	return moment(date).format(format);
};

export const handleReadMoreText = (text, len) => {
  const truncatedText = text.slice(0, len);
  return (
    <>
      {truncatedText}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-read-more">{text}</Tooltip>}
      >
        <span style={{ cursor: 'pointer', color: 'blue' }}>...</span>
      </OverlayTrigger>
    </>
  );
};
