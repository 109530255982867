
import React from "react";
import { AdminPrivateLayout, AdminPublicLayout, } from "../../layouts";
import publicRoutes from "./public"
import privateRoutes from "./private"
export const adminRoutes = () => {
  return [
    {
      element: <AdminPublicLayout />,
      children: [...publicRoutes()],
    },
    {
      element: <AdminPrivateLayout />,
      children: [...privateRoutes()],
    },
  ];
};
