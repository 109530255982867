

export const TechnicalAPI = {
  getTechnical: {
    url: '/admin/technical-support',
    method: 'GET',
  },
  updateTechnical: {
    url: '/admin/technical-support',
    method: 'PATCH',
  },
}