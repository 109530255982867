import React, { useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Button, Col, Form } from 'react-bootstrap';
import DaySelection from '../daySelection';
import PropTypes from 'prop-types';

const DoctoreRoleIsClose = ({ errors, control, watch }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'schedules',
  });
  const schedules = watch('schedules');
  const selectedDays =
    schedules?.map(schedule => schedule.day).filter(Boolean) || [];

  useEffect(() => {
    if (fields.length === 0) {
      prepend({ day: '', start: '', end: '', isClose: false });
    }
  }, [fields, prepend]);

  return (
    <>
      {fields?.map((item, index) => (
        <div key={item.id} className='mb-3 row pe-0'>
          <DaySelection
            control={control}
            name={`schedules[${index}].day`}
            label='Day'
            errors={errors?.schedules?.[index]?.day}
            disabledOptions={selectedDays.filter((day, i) => i !== index)}
          />
          <Col lg={4} xxl={2}>
            <Form.Group>
              <Form.Label>Start Time</Form.Label>
              <Controller
                control={control}
                name={`schedules[${index}].start`}
                render={({ field }) => <Form.Control type='time' {...field} />}
              />

              <Form.Control.Feedback type='invalid' className='d-block'>
                {errors?.schedules?.[index]?.start?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={4} xxl={2}>
            <Form.Group>
              <Form.Label>End Time</Form.Label>
              <Controller
                control={control}
                name={`schedules[${index}].end`}
                render={({ field }) => <Form.Control type='time' {...field} />}
              />
              <Form.Control.Feedback type='invalid' className='d-block'>
                {errors?.schedules?.[index]?.end?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col
            lg={4}
            xxl={2}
            className='d-flex align-items-center  gap-2 mt-2 pe-0'
          >
            <Form.Group className=''>
              <Controller
                control={control}
                name={`schedules[${index}].isClose`}
                render={({ field }) => (
                  <Form.Check
                    type='checkbox'
                    label='Is Close'
                    {...field}
                    checked={field.value}
                  />
                )}
              />
            </Form.Group>
            {fields.length > 1 && (
              <Button variant='danger' onClick={() => remove(index)}>
                Remove
              </Button>
            )}
          </Col>
        </div>
      ))}

      {errors?.schedules && (
        <div className='text-danger'>{errors.schedules.message}</div>
      )}

      {schedules?.length < 7 && (
        <div className='text-center'>
          <Button
            variant='primary'
            className='mb-4 add-new-btn '
            onClick={() =>
              append({ day: '', start: '', end: '', isClose: false })
            }
          >
            Add New Day
          </Button>
        </div>
      )}
    </>
  );
};

DoctoreRoleIsClose.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.shape({
    schedules: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.object,
          start: PropTypes.object,
          end: PropTypes.object,
          message: PropTypes.string,
        })
      ),
      PropTypes.string,
    ]),
  }),
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};

export default DoctoreRoleIsClose;
