import React from "react";
import { PiFileText } from "react-icons/pi";
import { AddEditCms, ManageCms, } from "../../../pages";

export default function route() {
  return [
    {
      path: "/admin/cms",
      name: "Content Management",
      key: "/admin/cms",
      private: true,
      belongsToSidebar: true,
      icon: <PiFileText size={32} />,
      element: <ManageCms />,
    },
    {
      path: "/admin/cms/add",
      name: "Add Users",
      key: "/admin/cms/add",
      private: true,
      belongsToSidebar: false,
      element: <AddEditCms />,
    },
    {
      path: "/admin/cms/:id",
      name: "Add Users",
      key: "/admin/cms/:id",
      private: true,
      belongsToSidebar: false,
      element: <AddEditCms />,
    },
  ];
}
