/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { showAlertMessage } from "../../common";
import { profilePlaceholder } from '../../../styles/image';
import { FaTimes } from 'react-icons/fa';

// eslint-disable-next-line react/prop-types
export default function ImageUploader({label,name,register,setValue,col = 6,errors,selectedImage,setSelectedImage,multiple = false,watch}) {
  const [previews, setPreviews] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
    const maxSize = 1024 * 1024;

    for (const file of files) {
      if (!allowedTypes.includes(file.type)) {
        showAlertMessage('Invalid file type. Only JPEG, PNG, GIF, and SVG are allowed.', "error");
        return;
      }
      if (file.size > maxSize) {
        showAlertMessage('File size must be less than 1MB.', "error");
        return;
      }
    }

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews(newPreviews);
    setFileNames(files.map(file => file.name));
    setValue(name, files || selectedImage);
    setSelectedImage(files || selectedImage);
  };


  const handleRemoveImage = (index) => {
    const newPreviews = previews.filter((_, i) => i !== index);
    const newFileNames = fileNames.filter((_, i) => i !== index);
    setPreviews(newPreviews);
    setFileNames(newFileNames);

    const updatedImages = selectedImage.filter((_, i) => i !== index);
    setSelectedImage(updatedImages);
    setValue(name, updatedImages);
  };
  useEffect(() => {
    if (!selectedImage?.includes('') && selectedImage?.length > 0 && !previews.length) {
      const preview = selectedImage?.map((item) => {
        // eslint-disable-next-line no-undef
        return `${process.env.REACT_APP_BASE_URL}${item?.document_link ? item?.document_link : item}`;
      });
      setPreviews(preview);
      setFileNames(selectedImage.map(img => img?.name || img));
    }
  }, [previews.length, selectedImage]);

  return (

    <Col lg={col}>
      <Form.Group className="mb-3" controlId={`form-${name}`}>
        <Form.Label>{label}</Form.Label>
        <br />
        <Form.Control
          type='file'
          {...register(name)}
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id={`custom-file-upload-${name}`}
          multiple={multiple}
        />
        <Button className='' onClick={() => document.getElementById(`custom-file-upload-${name}`).click()}>
          Choose File
        </Button>

        {previews?.length > 0 && (
          <div className="mt-3 d-flex">
            {previews?.map((preview, index) => (
              // eslint-disable-next-line react/no-unknown-property
              <div key={index} xs={4} className="position-relative"
                style={{
                  width: 100,
                  height: 100,
                }}>
                <img
                  src={preview || profilePlaceholder}
                  alt={`Selected-${index}`}
                  className="rounded-circle p-1"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop in case of placeholder failure
                    e.target.src = profilePlaceholder;
                  }}
                />
                <Button
                  variant="danger"
                  size="sm"
                  className="cursor-pointer position-absolute top-0 p-0 start-100 translate-middle rounded-circle"
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => handleRemoveImage(index)}
                >
                  <FaTimes />
                </Button>
              </div>
            ))}
          </div>
        )}

        {errors.profile_image && !watch().profile_image.length > 0 ? <p className='text-danger'>{errors.profile_image.message}</p> : ""}
        {errors.certifications && !watch().certifications.length > 0 ? <p className='text-danger'>{errors?.certifications.message}</p> : ""}

        {errors?.catelogs_image && !watch().catelogs_image.length > 0 ? <p className='text-danger'>{errors?.catelogs_image?.message}</p> : ""}

      </Form.Group>
    </Col>

  );
}


