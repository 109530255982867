import React from "react";
import { AdminLogin, ForgotPassword, ResetPassword } from "../../pages";

export default function route() {
  return [
    {
      path: "/",
      name: "admin ",
      key: "/",
      private: false,
      belongsToSidebar: false,
      element: <h2>Hello there</h2>,
    },
    {
      path: "/login",
      name: "admin login",
      key: "/login",
      private: false,
      belongsToSidebar: false,
      element: <AdminLogin />,
    },
    {
      path: "/forgot-password",
      name: "forgot password",
      key: "/forgot-password",
      private: false,
      belongsToSidebar: false,
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      name: "reset password",
      key: "/reset-password",
      private: false,
      belongsToSidebar: false,
      element: <ResetPassword />,
    }
  ]
}