import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams } from "react-router-dom";
import { DatePicker, RadioInput } from "../UiElement";
import DoctoreRoleIsClose from "../UiElement/doctoreRoleIsClose";
import ImageMultiUploader from "../UiElement/multiUpload";
import SelectInput from "../UiElement/selectInput";
import FormInput from "../UiElement/textInput";
import Select from "react-select";
import "react-loading-skeleton/dist/skeleton.css";

// eslint-disable-next-line react/display-name
export const AddEditUserForm = React.memo(
  ({
    handleSubmit,
    register,
    errors,
    control,
    onSubmit,
    setValue,
    watch,
    setSelectedRole,
    handleCheck,
    setHandlCheck,
    selectedImage,
    setSelectedImage,
    cityStateCountry,
    userDataLoading,
    clearErrors,
    userDetail,
    languageData,
    treatmentData
  }) => {
    const { id } = useParams();

    useEffect(() => { }, [watch, selectedImage]);

    const genderOptions = [
      { label: "Male", value: 1 },
      { label: "Female", value: 0 },
    ];
    const surgerOptions = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

    const roleOptions = [
      { name: "Patient", isoCode: 3 },
      { name: "Doctor", isoCode: 2 },
    ];

    return (
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Row>
          <FormInput
            label="First Name"
            type="text"
            placeholder="Enter your first name"
            name="first_name"
            register={register}
            errors={errors}
            isLoading={userDataLoading}
          />
          <FormInput
            label="Last Name"
            type="text"
            placeholder="Enter your last name"
            name="last_name"
            register={register}
            errors={errors}
            isLoading={userDataLoading}
          />
          <FormInput
            label="Email"
            type="email"
            placeholder="Enter your email"
            name="email"
            register={register}
            errors={errors}
            disabled={id}
            isLoading={userDataLoading}
          />
          {!id && (
            <FormInput
              label="Password"
              type="password"
              placeholder="Enter your password"
              name="password"
              register={register}
              errors={errors}
              isLoading={userDataLoading}
            />
          )}


          <SelectInput
            label="Role"
            name="role_id"
            options={roleOptions}
            isLoading={userDataLoading}
            register={register}
            errors={errors}
            setValue={setValue}
            onChange={(val) => setSelectedRole(val?.target?.value)}
            disabled={userDetail?.role_id}
          />
          {Number(watch()?.role_id) === 3 &&
            <><FormInput
              label="Weight"
              type="text"
              placeholder="Enter your weight"
              name="weight"
              register={register}
              errors={errors}
              // disabled={id}
              isLoading={userDataLoading}
            />
              <FormInput
                label="Height"
                type="text"
                placeholder="Enter your height"
                name="height"
                register={register}
                errors={errors}
                // disabled={id}
                isLoading={userDataLoading}
              />
              <FormInput
                label="Medical problem"
                type="text"
                placeholder="Enter your medical problem"
                name="medical_problem"
                register={register}
                errors={errors}
                // disabled={id}
                isLoading={userDataLoading}
              />
              {/* <FormInput
                label="Result"
                type="text"
                placeholder="Enter your result"
                name="result"
                register={register}
                errors={errors}
                // disabled={id}
                isLoading={userDataLoading}
              /> */}
              {/* <RadioInput
                label="Is any surgeries in past"
                name="surgeries_in_past"
                options={surgerOptions}
                register={register}
                validation={{ required: "Please select your surgery." }}
                errors={errors}
                col={6}
                isLoading={userDataLoading}
                selectedValue={userDetail?.gender}
              /> */}
              <RadioInput
                label="Taking any medication"
                name="taking_medication"
                options={surgerOptions}
                register={register}
                validation={{ required: "Please select your taking any medication" }}
                errors={errors}
                col={6}
                isLoading={userDataLoading}
                selectedValue={userDetail?.gender}
              />
              {/* <FormInput
                label="Medication description"
                type="text"
                placeholder="Enter your medication description"
                name="taking_medication_description"
                register={register}
                errors={errors}
                // disabled={id}
                isLoading={userDataLoading}
              /> */}
              <RadioInput
                label="Smoke"
                name="smoke"
                options={surgerOptions}
                register={register}
                validation={{ required: "Please select smoke " }}
                errors={errors}
                col={6}
                isLoading={userDataLoading}
                selectedValue={userDetail?.gender}
              />
            </>
          }
          {Number(watch()?.role_id) === 2 && (
            <>
              {/* <FormInput
                label="Qualification"
                type="text"
                placeholder="Enter your phone number"
                name="qualification"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              /> */}
              <Col lg={6} className="mb-3">
                <Form.Label>Treatment</Form.Label>
                <Controller
                  name="qualification"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isMulti
                      options={treatmentData}
                      value={field.value || []}
                      onChange={(val) => field.onChange(val)}
                      getOptionLabel={(e) => e?.name}
                      getOptionValue={(e) => e?.value}
                      classNamePrefix="react-select"
                    />
                  )}
                />
                {errors?.qualification && (
                  <p className="text-danger mt-1">{errors?.qualification?.message}</p>
                )}
              </Col>
              <DoctoreRoleIsClose
                control={control}
                name="is_close"
                label="Is Close"
                errors={errors}
                watch={watch}
              />

              <Col lg={6} className="mb-3" >
                <label className="mb-1">{"Phone Number"}</label>

                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        country={'us'}
                        value={field.value}
                        onChange={(phone) => field.onChange(phone)}
                      />
                      {errors.phone_number && (
                        <p className="text-danger mt-1">
                          {errors.phone_number.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Col>
              <FormInput
                label="Bio"
                type="text"
                placeholder="Enter your bio"
                name="bio"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              />
              <FormInput
                label="Company Name"
                type="text"
                placeholder="Enter your company name"
                name="company_name"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              />
              <FormInput
                label="Website URL"
                type="text"
                placeholder="Enter your website URL"
                name="web_url"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              />
              <Col xs={6}>
                <label className="mb-1">Language</label>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isMulti
                      options={languageData}
                      value={field.value || []}
                      onChange={(val) => field.onChange(val)}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.code}
                      classNamePrefix="react-select"
                    />
                  )}
                />
                {/* {errors.web_urls && (
                  <p className="text-danger mt-1">{errors.web_urls.message}</p>
                )} */}
              </Col>

            </>
          )}
          {Number(watch()?.role_id) !== 2 && (
            <DatePicker
              placeholder="Enter your date of birth"
              label="Date of birth"
              name="dob"
              register={register}
              errors={errors}
              defaultValue={new Date()}
              setValue={setValue}
              isLoading={userDataLoading}
              clearErrors={clearErrors}
            />
          )}
          {Number(watch()?.role_id) !== 2 && (
            <>
              <RadioInput
                label="Gender"
                name="gender"
                options={genderOptions}
                register={register}
                validation={{ required: "Please select your gender." }}
                errors={errors}
                col={6}
                isLoading={userDataLoading}
                selectedValue={userDetail?.gender}
              />
            </>
          )}
          <SelectInput
            label="Country"
            name="country"
            options={cityStateCountry.country}
            isLoading={userDataLoading}
            register={register}
            errors={errors}
            setValue={setValue}
            isCountryStateCity={true}
            selectedValue={watch("country")}
          />
          <SelectInput
            label="State"
            name="state"
            options={cityStateCountry.state}
            isLoading={userDataLoading}
            register={register}
            errors={errors}
            setValue={setValue}
            selectedValue={watch().state}
            isCountryStateCity={true}
          />
          <SelectInput
            label="City"
            name="city"
            options={cityStateCountry.city}
            isLoading={userDataLoading}
            register={register}
            errors={errors}
            setValue={setValue}
            isCountryStateCity={true}
          />
          {Number(watch()?.role_id) === 2 && (
            <>
              {/* <ImageUploader
                label="Certification"
                name="certifications"
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                col={6}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                multiple={true}
              /> */}
              <h3>Upload Images</h3>

              <ImageMultiUploader
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                useFormMethods={{ errors, register, handleSubmit, setValue, watch }} />
              <FormInput
                label="Address Line 1"
                type="text"
                placeholder="Enter your address line 1"
                name="address_1"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              />
              <FormInput
                label="Address Line 2"
                type="text"
                placeholder="Enter your address line 2"
                name="address_2"
                register={register}
                errors={errors}
                isLoading={userDataLoading}
              />
              <Col xs={6} className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Attach social media links."
                  onChange={(e) =>
                    setHandlCheck({
                      ...handleCheck,
                      isSocialMedia: e.target.checked,
                    })
                  }
                />
              </Col>

              {handleCheck?.isSocialMedia && (
                <>
                  <FormInput
                    label="Instagram URL"
                    type="text"
                    placeholder="Enter your Instagram URL"
                    name="instagram_url"
                    register={register}
                    errors={errors}
                    isLoading={userDataLoading}
                  />
                  <Form.Check
                    type="checkbox"
                    label="I don't have Instagram"
                    onChange={(e) =>
                      setHandlCheck({
                        ...handleCheck,
                        isInstagram: e.target.checked,
                      })
                    }
                  />

                  <FormInput
                    label="TikTok URL"
                    type="text"
                    placeholder="Enter your TikTok URL"
                    name="tiktok_url"
                    register={register}
                    errors={errors}
                    isLoading={userDataLoading}
                  />
                  <Form.Check
                    type="checkbox"
                    label="I don't have TikTok"
                    onChange={(e) =>
                      setHandlCheck({
                        ...handleCheck,
                        isTiktok: e.target.checked,
                      })
                    }
                  />
                </>
              )}
            </>
          )}
        </Row>
        <Button
          variant="primary"
          type="submit"
          disabled={handleCheck?.isLoading}
        >
          {id ? "Save" : "Add"}
        </Button>
      </Form>
    );
  }
);

// Define prop types
AddEditUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  handleCheck: PropTypes.object.isRequired,
  setHandlCheck: PropTypes.func.isRequired,
  selectedImage: PropTypes.any, // Define the type for selectedImage
  setSelectedImage: PropTypes.func.isRequired,
  cityStateCountry: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  userDataLoading: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired,
  userDetail: PropTypes.object,
  languageData: PropTypes.array.isRequired,
  treatmentData: PropTypes.array.isRequired,
};