import React,{ Suspense } from 'react';
import { routes } from './routes';
import { useRoutes } from 'react-router-dom';
import "./styles/sass/app.scss"
import { AlertMessage } from './components';

function App() {
  function RouteLayout({ path }) {
    const element = useRoutes(path);
    return element;
  }

  return (
    <>
      <Suspense fallback={<div className="d-flex align-items-center justify-content-center h-100 mainLoader">Loading</div>}>
        <RouteLayout path={routes()} />
        <AlertMessage />
      </Suspense>
    </>

  );
}

export default App;
