import React from "react";
import { PiHouse } from "react-icons/pi";
import { ChangePassword, Profile } from "../../../pages";

export default function route() {
  return [
    {
      path: "/admin/profile",
      name: "Dashboard",
      key: "/admin/profile",
      private: true,
      belongsToSidebar: false,
      icon: <PiHouse size={24} />,
      element: <Profile />,

    },
    {
      path: "/admin/change-password",
      name: "Change Password",
      key: "/admin/change-password",
      private: true,
      belongsToSidebar: false,
      icon: <PiHouse size={24} />,
      element: <ChangePassword />,

    },
  ]
}