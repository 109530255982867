import React from "react";
import { Modal, } from "react-bootstrap";
import PropTypes from 'prop-types';

const CommonModal = ({ show, handleClose, title, children, size = "md", bodyClass = "" }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size={size}>
      <Modal.Header closeButton className="px-4 py-2">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-4 py-2 ${bodyClass}`}>{children}</Modal.Body>

      {/* {footer && (
        <Modal.Footer>
          {footer}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      )} */}
    </Modal>
  );
};

CommonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
  size: PropTypes.string,
  bodyClass: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default CommonModal;
