import { AuthAPI } from "../../apiendpoints";
import { APIrequest } from "../../utils";


export const AuthService = {
  loginService: async (bodyData1) => {
    const bodyData = {
      ...bodyData1,
    }
    try {
      const response = await APIrequest({
        method: AuthAPI.login.method,
        url: AuthAPI.login.url,
        bodyData,
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  profileService: async (fromData) => {
    try {
      const response = await APIrequest({
        method: AuthAPI.profile.method,
        url: AuthAPI.profile.url,
        fromData,
      });
      return response
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },
  changePasswordService: async (bodyData) => {
    try {
      const response = await APIrequest({
        method: AuthAPI.changePassword.method,
        url: AuthAPI.changePassword.url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  profileGetService: async () => {
    try {
      const response = await APIrequest({
        method: AuthAPI.profileGet.method,
        url: AuthAPI.profileGet.url,
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  forgotService: async (bodyData) => {
    try {
      const response = await APIrequest({
        method: AuthAPI.forgotPassword.method,
        url: AuthAPI.forgotPassword.url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  resetService: async (bodyData) => {
    try {
      const response = await APIrequest({
        method: AuthAPI.resetPassword.method,
        url: AuthAPI.resetPassword.url,
        bodyData
      });
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};
