/* eslint-disable react/display-name */
import { Button, Form, Row } from "react-bootstrap";
import { FormInput } from "../UiElement";
import React from "react";

// eslint-disable-next-line react/prop-types
export const TechnicalSupportForm = React.memo(({ handleSubmit, register, errors, onSubmit, isLoading, userDataLoading }) => {
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <FormInput
            label="Email Address"
            type="email"
            placeholder="Enter your email address"
            name="email"
            register={register}
            errors={errors}
            col={6}
            isLoading={userDataLoading}
          />
          <FormInput
            label="Phone Number"
            type="text"
            placeholder="Enter your phone number"
            name="phone_number"
            register={register}
            errors={errors}
            col={6}
            isLoading={userDataLoading}


          />
        </Row>
        <Button variant="primary" type="submit" disabled={isLoading}  >
          {isLoading ? "...loading" : "Update"}
        </Button>
      </Form>
    </>
  );
}
)